import './PopperButton.scss';

import { MoreVert } from '@mui/icons-material';
import {
  Button,
  ClickAwayListener,
  Fade,
  IconButton,
  Paper,
  Popper,
  PopperPlacementType,
} from '@mui/material';
import { useBooleanToggle } from 'primary/hooks/useBooleanToggle';
import React, { ReactElement, ReactNode, RefObject, useRef } from 'react';

export type PopperButtonsConf = {
  label?: string;
  icon?: ReactElement;
  onlyIcon?: boolean;
  action?: () => void;
  button?: ReactElement;
};
export type PopperName =
  | 'account-menu'
  | 'structure-item-menu'
  | 'relation-item-menu'
  | 'create-menu'
  | 'notification-menu'
  | 'message-menu'
  | 'user-profil-preview'
  | 'members-structure-paramAction'
  | 'structure-profil-preview';

type PopperButtonProps = {
  disablePortal?: boolean;
  conf: PopperButtonsConf[];
  iconPopper?: ReactElement;
  popperName: PopperName;
  placement: PopperPlacementType;
  disabled?: boolean;
  button?: (
    togglePopper: (
      e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>,
    ) => void,
    ref: RefObject<HTMLButtonElement>,
  ) => ReactElement;
};

type PopperButtonFreeContentProps = {
  iconPopper?: ReactElement;
  popperName: PopperName;
  placement: PopperPlacementType;
  disabled?: boolean;
  button?: (
    togglePopper: (
      e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>,
    ) => void,
    ref: RefObject<HTMLButtonElement>,
  ) => ReactElement;
  children: ReactNode;
};

export const PopperButton = ({
  conf,
  iconPopper,
  popperName,
  placement,
  button,
  disablePortal,
}: PopperButtonProps) => {
  const [isOpen, toggle, open, close] = useBooleanToggle();
  const anchorEl = useRef<HTMLButtonElement>(null);
  const handleToggle = (
    e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>,
  ) => {
    toggle();
    return e;
  };

  return (
    <div className={'popperButton'}>
      {!!button && button(handleToggle, anchorEl)}
      {!button && (
        <IconButton
          onClick={handleToggle}
          aria-controls={isOpen ? popperName : undefined}
          aria-haspopup="true"
          aria-expanded={isOpen ? 'true' : undefined}
          ref={anchorEl}
          aria-describedby={popperName}
          style={{ zIndex: 600 }}
        >
          {iconPopper && iconPopper}
          {!iconPopper && <MoreVert />}
        </IconButton>
      )}
      <Popper
        id={popperName}
        key={popperName}
        open={isOpen}
        anchorEl={anchorEl.current}
        placement={placement}
        aria-hidden={!isOpen}
        disablePortal={disablePortal}
        transition
      >
        {({ TransitionProps }) => {
          return (
            <ClickAwayListener
              onClickAway={(e) => {
                close();
              }}
            >
              <Fade {...TransitionProps} timeout={350}>
                <Paper className={'popperButton__menu'}>
                  {conf.map((item) => (
                    <>
                      {!item.button && item.onlyIcon && (
                        <IconButton
                          onClick={() => {
                            close();
                            item?.action?.();
                          }}
                        >
                          {item.icon}
                        </IconButton>
                      )}
                      {!item.button && !item.onlyIcon && (
                        <div
                          onClick={(e) => {
                            close();
                            return e;
                          }}
                        >
                          <Button
                            color={'inherit'}
                            variant={'text'}
                            className={'linkMenu'}
                            style={{ width: '100%' }}
                            onClick={(e) => {
                              item?.action?.();
                            }}
                          >
                            {item.icon && item.icon}
                            {item.label}
                          </Button>
                        </div>
                      )}
                      {item.button && (
                        <div
                          onClick={(e) => {
                            close();
                            return e;
                          }}
                        >
                          {item.button}
                        </div>
                      )}
                    </>
                  ))}
                </Paper>
              </Fade>
            </ClickAwayListener>
          );
        }}
      </Popper>
    </div>
  );
};

export const PopperButtonFreeContent = ({
  popperName,
  placement,
  button,
  children,
  iconPopper,
}: PopperButtonFreeContentProps) => {
  const [isOpen, toggle, open, close] = useBooleanToggle();
  const anchorEl = useRef<HTMLButtonElement>(null);
  const handleToggle = (
    e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLButtonElement>,
  ) => {
    toggle();
  };

  return (
    <div className={'popperButton'}>
      {!!button && button(handleToggle, anchorEl)}
      {!button && (
        <IconButton
          onClick={handleToggle}
          aria-controls={isOpen ? popperName : undefined}
          aria-haspopup="true"
          aria-expanded={isOpen ? 'true' : undefined}
          ref={anchorEl}
          style={{ zIndex: 600 }}
        >
          {iconPopper && iconPopper}
          {!iconPopper && <MoreVert />}
        </IconButton>
      )}
      <Popper
        key={popperName}
        open={isOpen}
        anchorEl={anchorEl.current}
        placement={placement}
        aria-hidden={!isOpen}
        transition
      >
        {({ TransitionProps }) => {
          return (
            <ClickAwayListener
              onClickAway={(e) => {
                close();
                return e;
              }}
            >
              <Fade {...TransitionProps} timeout={350}>
                <Paper className={'popperButton__menu'}>{children}</Paper>
              </Fade>
            </ClickAwayListener>
          );
        }}
      </Popper>
    </div>
  );
};
