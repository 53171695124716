import {
  getBorderNeonStyleCustom,
  getBorderNeonStyleDefault,
  getNeonBarreStyleCustom,
} from 'assets/borderNeons';
import { PRIVACY_CODE } from 'domain/privacy/ProfilPrivacy';
import { Profil } from 'domain/profil/Profil';
import { StructureProfil } from 'domain/profil/StructureProfil';
import { UserProfil } from 'domain/profil/UserProfil';

export const getNeonBorderStyle = (color?: string) => {
  return !color ? getBorderNeonStyleDefault() : getBorderNeonStyleCustom(color);
};

export const getNeonBarreStyle = (color?: string) => {
  return getNeonBarreStyleCustom(color);
};

export const isUserProfil = (
  profil: UserProfil | StructureProfil,
): profil is UserProfil => {
  return Object.hasOwn(profil, 'idPlayer');
};

export const isStructureProfil = (
  profil: StructureProfil | UserProfil,
): profil is StructureProfil => {
  return Object.hasOwn(profil, 'structure');
};

export const getName = (profil: Profil) => {
  if (isStructureProfil(profil)) {
    return profil.structure?.name;
  } else if (isUserProfil(profil)) {
    return profil.idPlayer;
  }
  return '';
};

export const isPrivacyRestricted = (profil: Profil, privacy: PRIVACY_CODE): boolean => {
  return !profil.privacies?.find((p) => p.code === privacy)?.isChecked;
};
