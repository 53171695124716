import './Title.scss';

import classNames from 'classnames';
import React, { ReactNode } from 'react';

type color = 'green' | 'orange';

interface TitleProps {
  title: string;
  subTitle?: string;
  level: 1 | 2 | 3 | 4 | 5 | 6;
  bordered?: boolean;
  threeQuarter?: boolean;
  accent?: boolean;
  compensatePadding?: boolean;
  color?: color;
  className?: string;
}

type ChildrenProps = {
  children: ReactNode;
  className?: string;
};

const Ash1 = ({ children, className }: ChildrenProps) => (
  <h1 className={className}>{children}</h1>
);
const Ash2 = ({ children, className }: ChildrenProps) => (
  <h2 className={className}>{children}</h2>
);
const Ash3 = ({ children, className }: ChildrenProps) => (
  <h3 className={className}>{children}</h3>
);
const Ash4 = ({ children, className }: ChildrenProps) => (
  <h4 className={className}>{children}</h4>
);
const Ash5 = ({ children, className }: ChildrenProps) => (
  <h5 className={className}>{children}</h5>
);
const Ash6 = ({ children, className }: ChildrenProps) => (
  <h6 className={className}>{children}</h6>
);
const componentByLevel = [Ash1, Ash2, Ash3, Ash4, Ash5, Ash6];

export const Title = ({
  title,
  subTitle,
  level,
  bordered,
  threeQuarter,
  accent,
  compensatePadding,
  color,
  className,
}: TitleProps) => {
  const Component = componentByLevel[level];

  return (
    <Component
      className={classNames(
        'title',
        {
          '-bordered': bordered,
          '-threeQuarter': threeQuarter,
          '-accent': accent,
          '-compensatePadding': compensatePadding,
          '-green': color && color === 'green',
        },
        className,
      )}
    >
      <>
        {title}
        <br />
        {subTitle && <small>{subTitle}</small>}
      </>
    </Component>
  );
};
