import './BannerPhotoOverview.scss';

import React, { FC } from 'react';

import { OtherStructureProfilBannerOverview } from './OtherStructureProfilBannerOverview';

interface ProfilBannerOverviewProps {
  className?: string;
  banner?: string;
  idStructureProfil?: number;
  style?: any;
}

export const WrapperOtherProfilBannerOverview: FC<ProfilBannerOverviewProps> = ({
  className,
  style,
  banner,
  idStructureProfil,
}) => {
  if (!idStructureProfil) return null;
  return (
    <OtherStructureProfilBannerOverview
      style={style}
      idStructureProfil={idStructureProfil}
      className={className}
      banner={banner}
    />
  );
};
