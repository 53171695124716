import { EventParticipantState } from 'domain/event/participants/EventParticipantState';

export interface RestEventParticipantState {
  totalRegistered: number;
  maxParticipants: number;
  totalWaitingApproove: number;
}

export function toEventParticipantState(
  restEventParticipantState: RestEventParticipantState,
): EventParticipantState {
  return new EventParticipantState(
    restEventParticipantState.totalRegistered,
    restEventParticipantState.maxParticipants,
    restEventParticipantState.totalWaitingApproove,
  );
}
