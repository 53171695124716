import { ProfilPrivacy } from 'domain/privacy/ProfilPrivacy';

import { Neon } from '../neon/Neon';

export class StructureProfilView {
  constructor(
    public readonly id: number,
    public readonly name: string,
    public readonly structureId: number,
    public readonly neon?: Neon,
    public readonly bannerImage?: string,
    public readonly isDefaultBanner?: boolean,
    public readonly privacies?: ProfilPrivacy[],
    public readonly neonPhoto?: string,
    public readonly neonBar?: string,
    public readonly neonLitleBar?: string,
    public readonly neonBorder?: string,
  ) {}
}
