import './StructureProfilFrontContent.scss';

import { InfoRounded, MoreVert, Settings } from '@mui/icons-material';
import { Button, Hidden, IconButton } from '@mui/material';
import classNames from 'classnames';
import { getNeonBarreStyle, getNeonBorderStyle } from 'domain/profil/Profil.func';
import { StructureProfil } from 'domain/profil/StructureProfil';
import { RolesStructureEnum } from 'domain/security/RolesStructureEnum';
import { Neon } from 'primary/Components/Neon/Neon';
import { PlatformsRow } from 'primary/Components/PlatformsRow/PlatformsRow';
import {
  PopperButton,
  PopperButtonsConf,
} from 'primary/Components/PopperButton/PopperButton';
import { ActionButtonJoinStructure } from 'primary/Components/Profils/User/ActionButton/ActionButtonJoinStructure';
import { ActionButtonLeaveStructure } from 'primary/Components/Profils/User/ActionButton/ActionButtonLeaveStructure';
import { ActionButtonSendMessage } from 'primary/Components/Profils/User/ActionButton/ActionButtonSendMessage';
import { ProfilTabsDetailComp } from 'primary/Components/Profils/User/tabs/ProfilTabsDetails';
import { menusProfilStructure } from 'primary/Components/Profils/User/TopBarMenuProfil/MenusProfil.config';
import { TopBarMenuProfil } from 'primary/Components/Profils/User/TopBarMenuProfil/TopBarMenuProfil';
import { routesConfig } from 'primary/Configs/Routes.config';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useTranslate } from 'primary/hooks/useTranslate';
import { socialNetworkList } from 'primary/network/network';
import { TalkLangsRow } from 'primary/TalkLangsRow/TalkLangsRow';
import React, { useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { RootState } from 'store';

import { WrapperOtherProfilBannerOverview } from '../../../User/WrapperOtherProfilBannerOverview';

type FrontContentProps = {
  profil: StructureProfil;
  onReturnCard: () => void;
  isPreview?: boolean;
  onLeave?: () => void;
  draftBanner?: File;
};

type ActionsButtonStructureProfilProps = {
  structureProfil: StructureProfil;
  isHidden: boolean;
};

const ActionsButtonStructureProfil = ({
  structureProfil,
  isHidden,
}: ActionsButtonStructureProfilProps) => {
  const navigate = useNavigate();
  const t = useTranslate();
  return (
    <div className={classNames('-btnParamContainer', { hidden: isHidden })}>
      <Button
        className={'-button'}
        variant={'contained'}
        color={'primary'}
        onClick={() =>
          navigate(routesConfig.parametersStructure.to(structureProfil?.structure?.id))
        }
      >
        {t('structure.parameters.btnToParam')}
      </Button>
    </div>
  );
};

type StructureProfilCardHeaderProps = {
  profil: StructureProfil;
  onLeave?: () => void;
  draftBanner?: File;
};

export const StructureProfilCardHeader = ({
  profil,
  onLeave,
  draftBanner,
}: StructureProfilCardHeaderProps) => {
  const connectedUser = useSelector((state: RootState) => state.user.connectedUser);
  const { authRepository } = useContextDependency();
  const neonBorderStyle = getNeonBorderStyle(profil.neonBorder);
  const neonBarreStyle = getNeonBarreStyle(profil.neonBar);
  const neonPhotoStyle = getNeonBorderStyle(profil.neonPhoto);
  console.log('NEON BAR STYLE', profil.neonBar);
  const strucutresOfUser = useSelector(
    (state: RootState) => state?.user?.structuresOfUser,
  );
  const member = strucutresOfUser?.find((s) => s?.structureProfil?.id === profil.id);
  const t = useTranslate();
  const isOwn = profil.structure?.members.find(
    (member) => member.user.id === connectedUser?.id && member.role.code === 'PRES',
  );
  const navigation = useNavigate();
  const handleClick = () => {
    navigation(routesConfig.otherStructureProfil.to(profil.id));
  };

  const handleClickParam = () => {
    navigation(routesConfig.parametersStructure.to(profil.structure?.id));
  };

  const isDisabled = !authRepository?.currentUser?.userProfil.id;

  const conf: PopperButtonsConf[] = useMemo(() => {
    if (
      profil.structure?.members.find(
        (member) =>
          member.user.userProfil.idProfil === authRepository?.currentUser?.userProfil.id,
      )?.role.code === RolesStructureEnum.PRES
    ) {
      return [
        {
          label: t('profil.userProfilAction.infosMore'),
          action: handleClick,
          icon: <InfoRounded />,
        },
        {
          label: t('profil.userProfilAction.parameters'),
          action: handleClickParam,
          icon: <Settings />,
        },
      ];
    }
    return [
      {
        label: t('profil.userProfilAction.infosMore'),
        action: handleClick,
        icon: <InfoRounded />,
      },
      {
        button: member ? (
          <ActionButtonLeaveStructure profil={profil} withIcon onLeave={onLeave} />
        ) : (
          <ActionButtonJoinStructure profil={profil} withIcon />
        ),
      },
      {
        button: <ActionButtonSendMessage profil={profil} withIcon />,
      },
    ];
  }, [profil]);
  const navigate = useNavigate();

  return (
    <>
      <div className={'-containerBanner'}>
        <div className={'-langs'}>
          <TalkLangsRow withoutCountry langs={profil?.talkLangs?.map((l) => l.code)} />
        </div>
        <WrapperOtherProfilBannerOverview
          banner={
            draftBanner ? URL.createObjectURL(draftBanner) : profil.bannerImagePublicUrl
          }
          className={classNames('-bannerImg')}
          style={neonPhotoStyle}
          idStructureProfil={profil.id}
        />
        <div className={'-platforms'}>
          <PlatformsRow
            big
            platforms={profil.platforms?.map((platform) => platform.code)}
            neon={profil?.neon}
          />
        </div>
      </div>
      <div className={'-conainterHeaderMid'}>
        <div className={'-name'}>
          {profil.structure?.name}
          <Hidden smUp>
            {isOwn && (
              <IconButton
                className={classNames('-settingsBtn')}
                style={neonBorderStyle}
                onClick={() =>
                  navigate(routesConfig.parametersStructure.to(profil?.structure?.id))
                }
              >
                <Settings />
              </IconButton>
            )}
          </Hidden>
        </div>

        <Hidden smDown>
          {isOwn && (
            <ActionsButtonStructureProfil structureProfil={profil} isHidden={false} />
          )}
        </Hidden>
        {!isOwn && (
          <PopperButton
            conf={conf}
            popperName={'user-profil-preview'}
            placement={'auto'}
            button={(togglePopper, ref) => (
              <>
                <Hidden smUp>
                  {!isDisabled && isOwn && (
                    <IconButton
                      className={classNames('-settingsBtn')}
                      style={neonBorderStyle}
                      onClick={togglePopper}
                    >
                      <MoreVert />
                    </IconButton>
                  )}
                </Hidden>
                <Hidden smDown>
                  <Button
                    ref={ref}
                    className={classNames('-pingBtn')}
                    style={neonBorderStyle}
                    onClick={togglePopper}
                  >
                    <span>{t('profil.details.pingBtn')}</span>
                  </Button>
                </Hidden>
              </>
            )}
          />
        )}
        <div className={'-networks'}>
          {socialNetworkList.map((network) => (
            <a
              key={network.value}
              target="_blank"
              href={profil[network.value]}
              className={classNames({
                '-present': (profil[network.value]?.length ?? 0) > 0,
              })}
              rel="noreferrer"
              title={
                profil[network.value] ||
                t('myProfil.noNetworkValue', { network: network.name })
              }
            >
              {network.icon({})}
            </a>
          ))}
        </div>
      </div>
      <div className={'-divider'}>
        <Neon style={neonBarreStyle} />
      </div>
      <div className={'-overlay'}></div>
    </>
  );
};

type StructureProfilCardContentWithRouteAndMenuProps = {
  profil: StructureProfil;
  inView?: boolean;
};
export const StructureProfilCardContentWithRouteAndMenu = ({
  profil,
  inView,
}: StructureProfilCardContentWithRouteAndMenuProps) => {
  const [isFixed, setIsFixed] = React.useState(false);

  return (
    <>
      <TopBarMenuProfil
        title={profil.name || profil.structure?.name || ''}
        confs={menusProfilStructure.main}
        isFixed={!inView}
        setIsFixed={setIsFixed}
      />

      <div
        className={classNames('structureProfilCard__content', {
          '-isTopBarFixed': !inView,
        })}
      >
        <Outlet context={{ profil: profil }} />
      </div>
    </>
  );
};

export const StructureProfilFrontContent = ({
  profil,
  onReturnCard,
  isPreview,
  onLeave,
  draftBanner,
}: FrontContentProps) => {
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });
  const neonBorderStyle = getNeonBorderStyle(profil.neonBorder);
  const styleBackground = isPreview
    ? {
        backgroundImage: `url("${profil.backgroundImage?.publicUrl}")`,
        backgroundSize: 'cover',
      }
    : {};
  return (
    <div
      className={classNames('frontCardStructureProfilContent')}
      style={{ ...styleBackground, ...neonBorderStyle }}
      onTouchStart={undefined}
      onTouchEnd={undefined}
      onClick={() => {
        if (isPreview) {
          onReturnCard();
        }
      }}
    >
      <div className={'frontCardStructureProfilContent__banner'} ref={ref}>
        <StructureProfilCardHeader
          profil={profil}
          onLeave={onLeave}
          draftBanner={draftBanner}
        />
      </div>
      <div className={classNames('frontCardStructureProfilContent__contentContainer')}>
        {isPreview && <ProfilTabsDetailComp profil={profil} />}
        {!isPreview && (
          <StructureProfilCardContentWithRouteAndMenu inView={inView} profil={profil} />
        )}
      </div>
    </div>
  );
};
