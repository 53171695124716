import './Participants.scss';

import { Alert, ThemeProvider } from '@mui/material';
import { EventStatusEnum } from 'domain/event/EventStatusEnum';
import {
  EventParticipant,
  EventParticipantFilters,
} from 'domain/event/participants/EventParticipant';
import { getIsRandomTeamState } from 'domain/event/UnpyEvent.func';
import { PageLayout } from 'primary/Components/PageLayout/PageLayout';
import { Title } from 'primary/Components/Title/Title';
import { EVENT_CONTEXT } from 'primary/context/event/EventContext';
import { EventParticipantDatatable } from 'primary/events/datatable/EventParticipantDatatableParameters';
import { ActionModalAddParticipant } from 'primary/events/modals/ActionModalAddParticipant';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useFetchWithFilterPage } from 'primary/hooks/useFetchWithFilterPage';
import { useResetFiltersOnUnmount } from 'primary/hooks/useResetFiltersOnUnmount';
import { useRetrieveFromDomain } from 'primary/hooks/useRetrieveFromDomain';
import { useTranslate } from 'primary/hooks/useTranslate';
import React, { useContext } from 'react';

import { themeParameters } from '../parameters.helper';

export const Participants = () => {
  const { event } = useContext(EVENT_CONTEXT);
  const { eventParticipantRepository, authRepository } = useContextDependency();
  const t = useTranslate();
  const staticFilters = {
    idEvent: event?.id,
  };
  const useFetchReturn = useFetchWithFilterPage<
    EventParticipant,
    EventParticipantFilters
  >({
    keyStoreFilters: 'event-participants-param-list',
    staticFilters: staticFilters,
    filters: {},
    callApi: (filters, page) => eventParticipantRepository.search(filters, page, 10),
  });

  const [participantsState, , , retry] = useRetrieveFromDomain(
    () => eventParticipantRepository.getEventParticipantsState(event?.id as number),
    undefined,
    !!event?.id,
  );

  if (!event) return null;

  useResetFiltersOnUnmount(useFetchReturn.reset);

  return (
    <div className={'eventParticipants'}>
      <PageLayout>
        <ThemeProvider theme={themeParameters}>
          <div className={'eventParticipants__header'}>
            <Title
              title={t('event.parameters.participants.title')}
              level={1}
              compensatePadding
              threeQuarter
              bordered
            />
            <ActionModalAddParticipant
              event={event}
              disabled={event.status !== EventStatusEnum.PUBLISH}
            />
          </div>
          {getIsRandomTeamState(event) && (
            <Alert severity={'info'}>
              <span>{t('event.parameters.participants.randomTeamNotice')}</span>
            </Alert>
          )}
          <EventParticipantDatatable
            participantsState={participantsState}
            useFetchReturn={useFetchReturn}
            reFetch={useFetchReturn.retry}
            event={event}
            readOnly={[EventStatusEnum.FINISH, EventStatusEnum.CANCELED].includes(
              event.status,
            )}
          />
        </ThemeProvider>
      </PageLayout>
    </div>
  );
};
