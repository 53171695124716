import { Skeleton } from '@mui/lab';
import { Divider, Hidden } from '@mui/material';
import classNames from 'classnames';
import { TypeTeamCodeEnum } from 'domain/event/TypeTeamCodeEnum';
import { UnpyEvent } from 'domain/event/UnpyEvent';
import { getMaxTeams } from 'domain/event/UnpyEvent.func';
import { UnpyEventView } from 'domain/event/UnpyEventView';
import { formatDate } from 'helper/date.helper';
import { EventTimelineInfos } from 'primary/classements/Ligue/Modal/Infos/EventTimelineInfos';
import { TimelineEventComp } from 'primary/Components/Event/TimelineEvent';
import { KeyValue } from 'primary/Components/KeyValue/KeyValue';
import { Title } from 'primary/Components/Title/Title';
import { EventViewPageContext } from 'primary/events/item/EventViewPage';
import { FreeRegisterHelper } from 'primary/helpers/FreeRegisterHelper';
import { HelperEventType } from 'primary/helpers/HelperEventType';
import { HelperPopper } from 'primary/helpers/HelperPopper';
import { RandomTeamsHelper } from 'primary/helpers/RandomTeamsHelper';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';
import { useOutletContext } from 'react-router-dom';

type InfosWrapperProps = {
  event?: UnpyEvent | UnpyEventView;
  readOnly?: boolean;
};

type InfosWithContextWrapperProps = {
  readOnly?: boolean;
};
export const InfosWithContext = ({ readOnly }: InfosWithContextWrapperProps) => {
  const t = useTranslate();
  const { event } = useOutletContext<EventViewPageContext>();
  return <InfosContent event={event} readOnly={readOnly} />;
};

export const InfosWithProps = ({ event, readOnly }: InfosWrapperProps) => {
  return <InfosContent event={event} readOnly={readOnly} />;
};

export const InfosContent = ({ event, readOnly }: InfosWrapperProps) => {
  const t = useTranslate();
  return (
    <div className={'eventViewPageBloc eventViewPageInfos'}>
      <Title
        title={t('event.eventViewPage.infos.title')}
        level={1}
        threeQuarter
        bordered
      />
      {!event && <Skeleton animation="wave" height={'100%'} width="100%" />}
      {event && (
        <div className={'-container'}>
          <div className={'-timelineContainer --scrollBarContent'}>
            <div className={'-buttonPopperContainer'}>
              <HelperPopper
                btnLabel={'En savoir plus sur la Timeline'}
                modal={{
                  title: (
                    <h3>
                      {t('event.parameters.timeline.infos.title')} <br />
                      <small>{t('event.parameters.timeline.infos.subTitle')}</small>
                    </h3>
                  ),
                }}
              >
                <EventTimelineInfos event={event} />
              </HelperPopper>
            </div>
            <TimelineEventComp
              readonly={readOnly}
              event={event}
              withoutActions
              withoutDraftState
              orientation={'horizontal'}
            />
          </div>
          <Hidden smDown>
            <Divider className={'-divider'} />
          </Hidden>
          <div className={'-infosContainer'}>
            <KeyValue
              alignStart
              keyValue={t('event.eventViewPage.header.textInfos.game')}
              value={t(`games.libelle.${event?.game.code}`)}
              className={'-keyValue'}
              accent
            />
            <KeyValue
              absolutePopper
              helperPopper={<HelperEventType />}
              alignStart
              keyValue={t('event.eventViewPage.header.textInfos.typeEvent')}
              value={t(`enum.typeEventCode.${event?.typeEvent.code}`)}
              className={'-keyValue'}
              accent
            />
            <KeyValue
              alignStart
              keyValue={t('event.eventViewPage.header.textInfos.typeTeam')}
              value={t(
                event?.typeTeam
                  ? `enum.typeTeamCode.${event?.typeTeam?.code as TypeTeamCodeEnum}`
                  : 'general.keyValueNoValue',
              )}
              className={'-keyValue'}
              accent
            />
            <KeyValue
              alignStart
              keyValue={t('event.eventViewPage.infos.maxTeam')}
              value={getMaxTeams(event)?.toString()}
              className={'-keyValue'}
              accent
            />
            <KeyValue
              alignStart
              keyValue={t('event.eventViewPage.infos.endSubscribeDate')}
              value={formatDate(event?.endSubscribeDate, 'readable(with hour)')}
              className={'-keyValue'}
              accent
            />
            <KeyValue
              alignStart
              keyValue={t('event.eventViewPage.infos.startDateTime')}
              value={formatDate(event?.startDateTime, 'readable(with hour)')}
              className={'-keyValue'}
              accent
            />
            <KeyValue
              alignStart
              keyValue={t('event.eventViewPage.randomTeamsLabel')}
              value={event?.randomTeams ? 'Oui' : 'Non'}
              helperPopper={<RandomTeamsHelper />}
              className={'-keyValue'}
              accent
            />
            <KeyValue
              absolutePopper
              helperPopper={<FreeRegisterHelper />}
              alignStart
              keyValue={t('event.eventViewPage.infos.freeRegister')}
              value={
                event?.freeRegister
                  ? t('event.eventViewPage.infos.freeRegisterValue')
                  : t('event.eventViewPage.infos.freeRegisterValidationValue')
              }
              className={'-keyValue -fullWidth'}
              accent
            />
            <KeyValue
              alignStart
              keyValue={t('event.eventViewPage.rules.title')}
              value={
                <div
                  className={classNames('text-area', '-size__medium', {
                    '-noContent': !(event as UnpyEvent)?.rules,
                  })}
                  contentEditable={true}
                  dangerouslySetInnerHTML={{
                    __html:
                      (event as UnpyEvent)?.rules ||
                      t('event.eventViewPage.infos.rules.noRules'),
                  }}
                ></div>
              }
              className={'-keyValue -fullWidth'}
              accent
            />
          </div>
        </div>
      )}
    </div>
  );
};

export const Infos = ({ event, readOnly }: InfosWrapperProps) => {
  if (event) {
    return <InfosWithProps event={event} readOnly />;
  } else {
    return <InfosWithContext readOnly />;
  }
};
