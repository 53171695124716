import './EventParticipantCardListItem.scss';

import { Cancel, Check, HourglassFull, Visibility } from '@mui/icons-material';
import { Button, Divider } from '@mui/material';
import classNames from 'classnames';
import { EventParticipant } from 'domain/event/participants/EventParticipant';
import { EventParticipantStatusEnum } from 'domain/event/participants/EventParticipantStatusEnum';
import { getIsRandomTeamState } from 'domain/event/UnpyEvent.func';
import { TagPlayer } from 'primary/Components/TagPlayer/TagPlayer';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';

type EventParticipantCardListItemProps = {
  participant: EventParticipant;
  actions?: {
    onClickAccept: (id: number) => () => void;
    onClickDecline: (id: number) => () => void;
    onClickDelete: (id: number) => () => void;
    disabled: boolean;
  };
  displayState?: boolean;
  asView?: boolean;
  openTeam?: () => void;
};
export const EventParticipantCardListItem = ({
  participant,
  actions,
  asView,
  displayState,
  openTeam,
}: EventParticipantCardListItemProps) => {
  const t = useTranslate();
  console.log('IS RANDOM', participant.event.randomTeams);
  return (
    <div
      className={classNames('eventParticipantCardListItemContainer', {
        '-fullWidth': displayState,
      })}
    >
      <div className={classNames('eventParticipantCardListitem', { '-asView': asView })}>
        <div className={'-header'}>
          <span className={'-teamName'}>{participant.team.name}</span>
          {displayState && (
            <div className={'-status'}>
              {participant?.status === EventParticipantStatusEnum.REGISTERED && (
                <>
                  <Check />
                  <span>
                    {t(`event.viewPage.myParticipation.status.${participant.status}`)}
                  </span>
                </>
              )}
              {participant?.status === EventParticipantStatusEnum.WAIT_VALIDATE && (
                <>
                  <HourglassFull />
                  <span>
                    {t(`event.viewPage.myParticipation.status.${participant.status}`)}
                  </span>
                </>
              )}
              {participant?.status === EventParticipantStatusEnum.REJECTED && (
                <>
                  <Cancel />
                  <span>
                    {t(`event.viewPage.myParticipation.status.${participant.status}`)}
                  </span>
                </>
              )}
            </div>
          )}
        </div>
        <Divider className={'-dividerEventParticipantCardListItem'} />
        <div className={'-players'}>
          {participant.team.players.map((player) => (
            <TagPlayer key={player?.player?.idPlayer} player={player.player} />
          ))}
        </div>
        {openTeam && (
          <div className={'-openTeam'}>
            <Button variant={'contained'} color={'secondary'} onClick={openTeam}>
              <Visibility />
              {getIsRandomTeamState(participant.event)
                ? t('event.teams.datatable.seeParticipation')
                : t('event.teams.datatable.seeTeam')}
            </Button>
          </div>
        )}
        {actions && (
          <div className={'-actions'}>
            {participant.status === EventParticipantStatusEnum.WAIT_VALIDATE && (
              <>
                <Button
                  variant={'contained'}
                  color={'success'}
                  onClick={actions.onClickAccept(participant.id)}
                  disabled={actions.disabled}
                >
                  {t('event.teams.datatable.acceptAction')}
                </Button>
                <Button
                  variant={'contained'}
                  color={'error'}
                  onClick={actions.onClickDecline(participant.id)}
                  disabled={actions.disabled}
                >
                  {t('event.teams.datatable.declineAction')}
                </Button>
              </>
            )}
            {participant.status === EventParticipantStatusEnum.REGISTERED && (
              <Button
                variant={'contained'}
                color={'error'}
                onClick={actions.onClickDelete(participant.id)}
                disabled={actions.disabled}
              >
                {t('event.teams.datatable.deleteAction')}
              </Button>
            )}
            {participant.status === EventParticipantStatusEnum.REJECTED && (
              <span>{t('event.teams.datatable.noAction')}</span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
