import { Cancel, NavigateNext } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Collapse, Grid, Switch, TextField } from '@mui/material';
import { IEventRepository } from 'domain/event/Event.repository';
import { EventParticipant } from 'domain/event/participants/EventParticipant';
import { PlayersNumberByTypeTeams, TypeTeam } from 'domain/event/TypeTeam';
import { TypeTeamCodeEnum } from 'domain/event/TypeTeamCodeEnum';
import { CreateTeamPlayerFields } from 'primary/Components/Event/CreateTeamPlayerFields';
import { EventRegisterFormData } from 'primary/Components/Event/RegisterEventFormStep/FormStepPlayers';
import { InputWrapper } from 'primary/Components/Input/InputWrapper';
import { StructureSelector } from 'primary/Components/Ping/StructureSelector';
import { sendEventToastMessage } from 'primary/Components/Toast/Toast.helper';
import { routesConfig } from 'primary/Configs/Routes.config';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useTranslate } from 'primary/hooks/useTranslate';
import { setTeamCreating } from 'primary/teams/store/TeamCreatingSlice';
import React, { useState } from 'react';
import { useController, UseFormReturn } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

type EventRegisterFormProps = {
  form: UseFormReturn<EventRegisterFormData>;
  typeTeam: TypeTeam;
  onChangeField?: (name: string, property: string, value?: string) => void;
  eventId: number;
  closeModal?: () => void;
  onSubmitted?: () => void;
  randomTeams?: boolean;
};
const useOnSubmit =
  (
    eventRepository: IEventRepository,
    eventId: number,
    onSubmitted: (eventPArticipant: EventParticipant) => void,
    setLoadingState: (loadingState: boolean) => void,
  ) =>
  (data: EventRegisterFormData) => {
    setLoadingState(true);
    eventRepository
      .createTeam(data, eventId)
      .then((data) => {
        onSubmitted(data);
        sendEventToastMessage('event.register.success', 'success');
      })
      .finally(() => {
        setLoadingState(false);
      });
  };
export const EventRegisterForm = ({
  form,
  typeTeam,
  onChangeField,
  eventId,
  closeModal,
  onSubmitted,
  randomTeams,
}: EventRegisterFormProps) => {
  const {
    register,
    handleSubmit,
    control,
    watch,
    getValues,
    formState: { isSubmitting, errors },
  } = form;
  const { eventRepository, authRepository } = useContextDependency();
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const onSubmit = useOnSubmit(
    eventRepository,
    eventId,
    (participant) => {
      dispatch(setTeamCreating(undefined));
      navigate(
        routesConfig.eventViewDetails.eventViewPlayersTeamOpen.to(
          eventId,
          participant.id,
        ),
      );
      closeModal?.();
      onSubmitted?.();
    },
    setLoading,
  );
  const structureControl = useController({
    control: control,
    name: 'structure',
  });
  const navigate = useNavigate();
  const t = useTranslate();
  const handleCancel = () => {
    closeModal?.();
    dispatch(setTeamCreating(undefined));
  };

  const isParticipationAsStructure = watch('participationAsStructure');
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        {!randomTeams && (
          <Grid item xs={12} sm={6}>
            <InputWrapper
              className={'eventAppearenceForm__field'}
              label={t('event.modal.nameLabel')}
              errors={errors}
              isSubmitting={isSubmitting}
              Input={TextField}
              inputProps={{
                placeholder: t('event.modal.namePlaceholder'),
                disabled: isSubmitting,
                ...register(`name`),
                variant: 'filled',
              }}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <InputWrapper
            label={t('event.modal.selectStructureHelper')}
            errors={errors}
            className={'unpy-form__field'}
            isSubmitting={isSubmitting}
            Input={Switch}
            noFullWidth
            inputProps={{
              ...register('participationAsStructure', { required: true }),
              defaultChecked: !!getValues('participationAsStructure'),
            }}
          />
          <Collapse in={isParticipationAsStructure && !!authRepository.currentUser?.id}>
            <StructureSelector
              userId={authRepository?.currentUser?.id as number}
              errors={errors}
              control={structureControl}
            />
          </Collapse>
        </Grid>
      </Grid>
      <div className={'-addingPlayerContainer'}>
        <div className={'-playersField'}>
          {PlayersNumberByTypeTeams[
            randomTeams ? TypeTeamCodeEnum.SOL : typeTeam.code
          ]?.map((nb, index) => (
            <CreateTeamPlayerFields
              randomTeams={randomTeams}
              key={nb + '-' + index}
              form={form}
              index={index}
              structureId={watch('structure')}
              disabledPlayer={index === 0}
              disabledRole={index === 0}
              onChangeField={onChangeField}
              eventId={eventId}
              creatorIdPlayer={authRepository.currentUser?.userProfil?.idPlayer as string}
            />
          ))}
        </div>
      </div>
      <Grid container>
        <Grid item xs={12}>
          <Button
            variant={'outlined'}
            color={'primary'}
            onClick={() => handleCancel()}
            className={'cancel'}
          >
            <Cancel />
            {t('event.register.cancelCreatingTeam')}
          </Button>
          <LoadingButton
            variant={'contained'}
            color={'primary'}
            type={'submit'}
            loading={isLoading}
          >
            <NavigateNext />
            {t('event.register.createTeam')}
          </LoadingButton>
        </Grid>
      </Grid>
    </form>
  );
};
