import './TeamMembersViewDatatable.scss';

import { Cancel, Check, ContentCopy } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Chip, IconButton, Stack } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { EventStatusEnum } from 'domain/event/EventStatusEnum';
import { PlayerRoleEnum } from 'domain/event/PlayerRoleEnum';
import { PlayerStatusEnum } from 'domain/event/PlayerStatusEnum';
import { PlayerTeam } from 'domain/event/PlayerTeam';
import { UnpyEvent } from 'domain/event/UnpyEvent';
import { UnpyEventView } from 'domain/event/UnpyEventView';
import { Team } from 'domain/team/Team';
import { Translate } from 'domain/translation/Translation.repository';
import { FormEditRoleField } from 'primary/events/modals/ModalTeamEvent';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useTranslate } from 'primary/hooks/useTranslate';
import { handleCopyLink } from 'primary/InvitationLink/invitationLink.func';
import React from 'react';

interface TeamsViewDatatableParametersProps {
  reFetch: () => void;
  team: Team;
  event?: UnpyEvent | UnpyEventView;
  disabled?: boolean;
}

export const getPlayerStatusObject = (player: PlayerTeam, t: Translate) => {
  if (player.invitationLink != null && !player.player) {
    return {
      label: t('team.modal.edit.statusWaitingSubscribe'),
      state: 'warning',
    };
  }
  if (player.status === PlayerStatusEnum.ACCEPTED)
    return {
      label: t('team.modal.edit.playerStatus.ACCEPTED'),
      state: 'warning',
    };
  if (player.status === PlayerStatusEnum.DECLINED)
    return {
      label: t('team.modal.edit.playerStatus.DECLINED'),
      state: 'error',
    };
  return {
    label: t('event.teams.datatable.statusWaiting'),
    state: 'info',
  };
};

const formatPlayersToRowData = (players: PlayerTeam[], t: Translate) => {
  return players.map((player, index) => ({
    id: player?.player?.id ?? index,
    name: player?.player?.idPlayer ?? index,
    statut: getPlayerStatusObject(player, t),
    action: player,
    role: player,
  }));
};

export const TeamMembersViewDatatable = ({
  team,
  event,
  reFetch,
  disabled,
}: TeamsViewDatatableParametersProps) => {
  const { teamRepository, authRepository } = useContextDependency();
  const t = useTranslate();
  const [isLoading, setIsLoading] = React.useState(false);

  const onClickLeaveTeam = () => () => {
    setIsLoading(true);
    teamRepository
      .leave(team.id)
      .then(() => reFetch())
      .finally(() => setIsLoading(false));
  };

  const onClickDelete = (playerId: number) => () => {
    setIsLoading(true);
    teamRepository
      .deleteMember(team.id, playerId)
      .then(() => reFetch())
      .finally(() => setIsLoading(false));
  };

  const onClickAccept = () => () => {
    setIsLoading(true);
    teamRepository
      .acceptTeamInvitationEvent(true, team.id, event?.id as number)
      .then(() => {
        reFetch();
      })
      .finally(() => setIsLoading(false));
  };

  const onClickDecline = () => () => {
    setIsLoading(true);
    teamRepository
      .declineTeamInvitationEvent(false, team.id)
      .then(() => {
        reFetch();
      })
      .finally(() => setIsLoading(false));
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('event.teams.datatable.labelPlayers'),
      flex: 1,
      headerClassName: '-header',
      headerAlign: 'center',
      sortable: false,
    },
    {
      field: 'role',
      headerName: t('event.teams.datatable.labelRole'),
      flex: 1,
      headerClassName: '-header',
      headerAlign: 'center',
      sortable: false,
      renderCell: (value: GridRenderCellParams<PlayerTeam>) => {
        const isCurrentPlayer =
          authRepository.currentUser?.userProfil?.id === value?.value?.player?.idProfil;
        const isCurrentUserChief =
          team.players.find(
            (value) =>
              value?.player?.idProfil === authRepository.currentUser?.userProfil.id,
          )?.role === PlayerRoleEnum.CHIEF;
        if (!value.value.player) return <></>;
        console.log('ROLE VEFORE', value.value.role);
        if (isCurrentUserChief && !isCurrentPlayer) {
          return (
            <FormEditRoleField
              key={value.value.player?.idProfil}
              player={value.value.player}
              initialRole={value.value.role}
              team={team}
              onSubmitted={() => {
                reFetch();
              }}
            />
          );
        } else {
          return (
            <span>{t(`enum.playerRole.${value.value?.role as PlayerRoleEnum}`)}</span>
          );
        }
      },
    },
    {
      field: 'statut',
      headerName: t('event.teams.datatable.labelStatut'),
      flex: 2,
      headerClassName: '-header',
      headerAlign: 'center',
      sortable: false,
      renderCell: (
        value: GridRenderCellParams<{
          label: string;
          state: 'success' | 'error' | 'warning';
        }>,
      ) => (
        <Chip
          label={value.value?.label}
          variant="outlined"
          color={value.value ? value.value.state : 'warning'}
        />
      ),
    },
    {
      field: 'action',
      headerName: t('event.teams.datatable.labelActions'),
      headerClassName: '-header',
      headerAlign: 'center',
      sortable: false,
      flex: 2,
      renderCell: (value: GridRenderCellParams<PlayerTeam>) => {
        const isCurrentPlayer =
          authRepository.currentUser?.userProfil?.id === value?.value?.player?.idProfil;
        const isCurrentUserChief =
          team.players.find(
            (value) =>
              value?.player?.idProfil === authRepository.currentUser?.userProfil.id,
          )?.role === PlayerRoleEnum.CHIEF;
        console.log('isCurrentUserChief', isCurrentUserChief);
        console.log('isDisabled', disabled);
        if (!value.value) return <></>;
        if (EventStatusEnum.PUBLISH !== event?.status) {
          return <></>;
        }
        return (
          <div className={'-actions'}>
            {value.value.invitationLink && !value.value.player && (
              <div className={'-copyLink'}>
                <span>{t('event.teams.datatable.copyLink')}</span>
                <IconButton onClick={handleCopyLink(value.value.invitationLink)}>
                  <ContentCopy />
                </IconButton>
              </div>
            )}
            {isCurrentUserChief &&
              value.value.player &&
              value.value.status === PlayerStatusEnum.PENDING && (
                <>
                  <LoadingButton
                    variant={'contained'}
                    color={'primary'}
                    onClick={onClickAccept()}
                    disabled={disabled}
                    loading={isLoading}
                  >
                    <Check />
                  </LoadingButton>
                  <LoadingButton
                    variant={'contained'}
                    color={'secondary'}
                    onClick={onClickDecline()}
                    disabled={disabled}
                    loading={isLoading}
                  >
                    <Cancel />
                  </LoadingButton>
                </>
              )}
            {isCurrentPlayer &&
              !isCurrentUserChief &&
              value.value.status === PlayerStatusEnum.ACCEPTED && (
                <LoadingButton
                  variant={'contained'}
                  color={'secondary'}
                  onClick={onClickLeaveTeam()}
                  title={
                    !isCurrentUserChief
                      ? t('event.teams.datatable.leaveTeamNotice')
                      : undefined
                  }
                  disabled={disabled}
                  loading={isLoading}
                >
                  {t('event.teams.datatable.leaveTeam')}
                </LoadingButton>
              )}
            {!isCurrentPlayer &&
              isCurrentUserChief &&
              value.value.status !== PlayerStatusEnum.PENDING && (
                <>
                  <LoadingButton
                    variant={'contained'}
                    color={'secondary'}
                    onClick={onClickDelete(value?.value?.player?.idProfil)}
                    disabled={disabled}
                    loading={isLoading}
                  >
                    {t('event.teams.datatable.deletePlayer')}
                  </LoadingButton>
                </>
              )}
          </div>
        );
      },
    },
  ];

  return (
    <div className={'teamsMemberViewDatatable'}>
      <DataGrid
        disableColumnFilter
        disableColumnMenu
        disableColumnSelector
        disableDensitySelector
        disableEval
        disableVirtualization
        className={'-parameters'}
        autoHeight
        hideFooter
        sx={{ color: 'white' }}
        components={{
          NoRowsOverlay: () => (
            <Stack height="100%" alignItems="center" justifyContent="center">
              {t('event.teams.datatable.noContentLabel')}
            </Stack>
          ),
        }}
        getRowId={(row) => row.name}
        rows={team.players ? formatPlayersToRowData(team.players, t) : []}
        columns={columns}
      />
    </div>
  );
};
