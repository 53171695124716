import { Box } from '@mui/material';
import { setModalJoinStructureClose } from 'domain/modal/store/ModalSlice';
import { setAllConnectedUserStructures } from 'domain/user/store/UserSlice';
import { JoinStructureForm } from 'primary/Components/Profils/User/ActionButton/Forms/JoinStructureForm';
import { WrapperModalAction } from 'primary/Components/Profils/User/ActionButton/Modals/WrapperModalAction';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useTranslate } from 'primary/hooks/useTranslate';
import ModalContainer from 'primary/ModalContainer/ModalContainer';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';

export interface ModalJoinStructureProps {
  isOpen: boolean;
  handleClose: () => void;
  structureProfilId?: number;
  name: string;
}

export const ModalJoinStructure = () => {
  const t = useTranslate();
  const dispatch = useDispatch();
  const { structureRepository, authRepository } = useContextDependency();
  const stateModal = useSelector((state: RootState) => state.modal.joinStructure);
  const handleClose = () => dispatch(setModalJoinStructureClose());
  const { structureProfilId, name } = stateModal.props as ModalJoinStructureProps;
  const strucutresOfUser = useSelector(
    (state: RootState) => state?.user?.structuresOfUser,
  );
  const member = strucutresOfUser?.find(
    (s) => s?.structureProfil?.id?.toString() === structureProfilId?.toString(),
  );

  const onSubmitted = () => {
    if (!authRepository.currentUser) return;
    handleClose();
    structureRepository
      .getStructuresOfUser(authRepository.currentUser.id)
      .then((structures) => {
        dispatch(setAllConnectedUserStructures(structures));
      });
  };

  if (!structureProfilId) return null;
  return (
    <ModalContainer
      handleClose={handleClose}
      isOpen={stateModal.isOpen}
      header={{
        title: t('modals.joinStructure.title', { name: name }),
        withButtonClose: true,
      }}
      content={
        <WrapperModalAction closeModal={handleClose}>
          <>
            {member && (
              <Box>
                {t('modals.joinStructure.alreadyMember', {
                  name: member?.structureProfil?.name,
                })}
              </Box>
            )}
            {!member && (
              <JoinStructureForm
                onSubmitted={onSubmitted}
                structureId={structureProfilId?.toString()}
                onCancel={() => handleClose()}
              />
            )}
          </>
        </WrapperModalAction>
      }
    />
  );
};
