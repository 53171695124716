import classNames from 'classnames';
import { UnpyLogo } from 'primary/Components/UnpyLogo/UnpyLogo';
import React from 'react';
import { SocialIcon } from 'react-social-icons';

import { Section } from '../../Components/Section/Section';
import { useTranslate } from '../../hooks/useTranslate';
import { HomeSectionProps } from '../Home';

export const HomeFollowSection = ({ className }: HomeSectionProps) => {
  const t = useTranslate();

  return (
    <Section className={className}>
      <div className={'-bigTitleContainer'}>
        <h2 className={'-title'}>{t('home.follow.title')}</h2>
        <UnpyLogo size={'bigger'} />
      </div>
      <div className={classNames('-unpySocialLinksContainer')}>
        <a
          className={'-unpyLink -insta'}
          href={'https://www.instagram.com/unpy.gg/'}
          target="_blank"
          rel="noreferrer"
        >
          <h2 className={'-title'}>{t('home.follow.instaTitle')}</h2>
          <SocialIcon network="instagram" href={'https://www.instagram.com/unpy.gg/'} />
        </a>
        <a
          className={'-unpyLink'}
          href={'https://twitter.com/Unpy__'}
          target="_blank"
          rel="noreferrer"
        >
          <h2 className={'-title'}>{t('home.follow.twitterTitle')}</h2>
          <SocialIcon network="x" href={'https://twitter.com/Unpy__'} />
        </a>
      </div>
    </Section>
  );
};
