import { ProfilPrivacy } from 'domain/privacy/ProfilPrivacy';
import { UnpyStatistiquesProfil } from 'domain/profil/UnpyStatistiquesProfil';

import { BackgroundImageView } from '../backgroundImage/BackgroundImageView';
import { GameProfil } from '../gameProfil/GameProfil';
import { Lang } from '../lang/Lang';
import { Neon } from '../neon/Neon';
import { Platform } from '../platform/Platform';
import { Structure } from '../structure/Structure';
import { Profil } from './Profil';

export interface StructureProfilFilters {
  games?: string[];
  platforms?: string[];
  experiences?: string[];
  langs?: string[];
  name?: string;
  asCreator?: boolean;
  asMember?: boolean;
}

export class StructureProfil extends Profil {
  constructor(
    id: number,
    games: GameProfil[],
    neon: Neon,
    backgroundImage?: BackgroundImageView,
    description?: string,
    gamingProfil?: string,
    lang?: string,
    platforms?: Platform[],
    video?: string,
    ping?: boolean,
    twitter?: string,
    facebook?: string,
    youtube?: string,
    instagram?: string,
    discord?: string,
    twitch?: string,
    talkLangs?: Lang[],
    privacies?: ProfilPrivacy[],
    unpyStatistiques?: UnpyStatistiquesProfil,
    neonPhoto?: string,
    neonBar?: string,
    neonBorder?: string,
    public readonly name?: string,
    public readonly structure?: Structure,
    public readonly bannerImage?: string,
    public readonly bannerImagePublicUrl?: string,
    public readonly defaultBanner?: boolean,
  ) {
    super(
      id,
      games,
      neon,
      backgroundImage,
      description,
      gamingProfil,
      lang,
      platforms,
      video,
      ping,
      twitter,
      facebook,
      youtube,
      instagram,
      discord,
      twitch,
      talkLangs,
      privacies,
      unpyStatistiques,
      neonPhoto,
      neonBar,
      undefined,
      neonBorder,
    );
  }
}
