import './Home.scss';

import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Button } from '@mui/material';
import classNames from 'classnames';
import { EVENT_OPEN_CREATE_UNPY_EVENT } from 'primary/Components/User/LoggedInUserAvatarMenu';
import { routesConfig } from 'primary/Configs/Routes.config';
import { HomeFollowSection } from 'primary/Home/Sections/HomeFollowSection';
import { SliderLastEvents } from 'primary/Home/Sliders/SliderLastEvents';
import { SliderLastRegisters } from 'primary/Home/Sliders/SliderLastRegisters';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useTranslate } from 'primary/hooks/useTranslate';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';

export type HomeSectionProps = {
  className?: string;
};

const ArrowLeft = (props: any) => {
  return (
    <KeyboardArrowLeft
      {...props}
      className={classNames(props.className, '-arrow', '-left')}
    />
  );
};

const ArrowRight = (props: any) => {
  return (
    <KeyboardArrowRight
      {...props}
      className={classNames(props.className, '-arrow', '-right')}
    />
  );
};

export const Home: FC = () => {
  const t = useTranslate();
  const navigate = useNavigate();
  const { authRepository } = useContextDependency();
  return (
    <div className={'home'}>
      <div className={'dualTitle'}>
        <h2>Bienvenue</h2>
        <h2>
          Unpy <span> e-sport </span> platform
        </h2>
      </div>
      <div className={'homeCard'}>
        <div className={'-neonLeftBar'}></div>
        <div className={'-linearGradientToRight'}></div>
        <div className={'-detailsPart'}>
          <div className={'-titleWithButtons'}>
            <h1>MERCATO</h1>
            <Button
              variant={'contained'}
              color={'primary'}
              onClick={() => {
                navigate(routesConfig.mercatoSubPath.mercatoPlayer.to());
              }}
            >
              Trouvez un joueur
            </Button>
            <Button
              variant={'contained'}
              color={'primary'}
              onClick={() => {
                navigate(routesConfig.mercatoSubPath.mercatoStructure.to());
              }}
            >
              Rechercher une structure
            </Button>
          </div>
          <Slider
            speed={500}
            slidesToScroll={1}
            rows={1}
            slidesToShow={1}
            className={'slider'}
            arrows
            dots
            nextArrow={<ArrowRight />}
            prevArrow={<ArrowLeft />}
            infinite
          >
            <div className={'-titleBloc'}>
              <div className={'explanation-content'}>
                <h2>Trouvez vos futures team mates !</h2>
                <p>
                  Utilisez le mercato pour agrandir votre réseau de joueurs et de
                  coéquipiers portentiels pour vous inscrire aux événements
                </p>
              </div>
            </div>
            <div className={'-titleBloc'}>
              <div className={'explanation-content'}>
                <h2>Structures e-sport amateurs et professionnels</h2>
                <p>
                  Créez ou rejoignez une structure e-sports niveau amateur ou
                  professionnel.
                </p>
              </div>
            </div>
          </Slider>
        </div>
        <div className={'-sliderPart'}>
          <SliderLastRegisters />
        </div>
      </div>
      <div className={'homeCard -reverse'}>
        <div className={'-neonRightBar'}></div>
        <div className={'-linearGradientToLeft'}></div>
        <div className={'-sliderPart'}>
          <SliderLastEvents />
        </div>
        <div className={'-detailsPart'}>
          <div className={'-titleWithButtons'}>
            <h1>EVENTS</h1>
            <Button
              variant={'contained'}
              color={'primary'}
              onClick={() => {
                if (authRepository?.currentUser) {
                  const event = new CustomEvent(EVENT_OPEN_CREATE_UNPY_EVENT);
                  window.document.dispatchEvent(event);
                } else {
                  authRepository.login(
                    window.location.origin +
                      window.location.pathname +
                      '?createEvent=true',
                  );
                }
              }}
            >
              Créer un événement
            </Button>
            <Button
              variant={'contained'}
              color={'primary'}
              onClick={() => {
                navigate(routesConfig.events.to());
              }}
            >
              Trouver un événement
            </Button>
          </div>
          <Slider
            speed={500}
            slidesToScroll={1}
            rows={1}
            slidesToShow={1}
            className={'slider'}
            arrows
            dots
            nextArrow={<ArrowRight />}
            prevArrow={<ArrowLeft />}
            infinite
          >
            <div className={'-titleBloc'}>
              <div className={'explanation-content'}>
                <h2>Organisez vos propres événements !</h2>
                <p>
                  Gérez entièrement vos événements, de la publication à la distribution en
                  passant par la gestion des inscriptions, des équipes et des scores
                  simplifiés par Unpy.
                </p>
              </div>
            </div>
            <div className={'-titleBloc'}>
              <div className={'explanation-content'}>
                <h2>Participez à des événements sur vos jeux favoris</h2>
                <p>
                  Faites grandir votre réseau, progressez, et affichez vos résultats sur
                  votre profil pour avoir une chance de vous faire recruter !
                </p>
              </div>
            </div>
          </Slider>
        </div>
      </div>
      <HomeFollowSection key={'home-section-5'} className={'home__section-5'} />
    </div>
  );
};
