import { Button } from '@mui/material';
import { EventParticipant } from 'domain/event/participants/EventParticipant';
import { canRegister } from 'domain/event/UnpyEvent.func';
import { UnpyEventView } from 'domain/event/UnpyEventView';
import { setTeamModalOpen } from 'domain/modal/store/ModalSlice';
import { LoadingScreen } from 'primary/Components/LoadingScreen/LoadingScreen';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'store';

import { UnpyEvent } from '../../../domain/event/UnpyEvent';
import { User } from '../../../domain/user/User';
import { routesConfig } from '../../Configs/Routes.config';
import { useTranslate } from '../../hooks/useTranslate';

interface EventActionProps {
  event: UnpyEvent | UnpyEventView;
  currentUser?: User;
  teamRegistered?: EventParticipant | null;
  openModalRegister: () => void;
  className: string;
  retry: () => void;
}

export const EventActions = ({
  event,
  currentUser,
  openModalRegister,
  className,
  retry,
  teamRegistered,
}: EventActionProps) => {
  const navigate = useNavigate();
  const t = useTranslate();
  const { authRepository } = useContextDependency();
  const isAccountLoaded = useSelector((state: RootState) => state.user.isAccountLoaded);
  console.log('EVENT ACTION', teamRegistered);
  const isCanRegister = canRegister(event);
  const userId =
    typeof event === UnpyEventView.name
      ? event.creator.id
      : (event as UnpyEvent).creator.userId;

  const dispatch = useDispatch();
  const openModalTeam = () => {
    dispatch(
      setTeamModalOpen({
        props: {
          event: event as UnpyEventView,
          teamRegistered: teamRegistered,
          afterChanges: retry as () => void,
        },
      }),
    );
  };

  if (!isAccountLoaded) {
    return <LoadingScreen loading />;
  }

  return (
    <div className={className}>
      {userId === currentUser?.id && (
        <Button
          variant={'contained'}
          onClick={() =>
            navigate(routesConfig.parametersDetailsEvent.parameterize.to(event.id))
          }
          color={'primary'}
          className={'-registerBtn'}
        >
          {t('event.eventViewPage.parametersBtn')}
        </Button>
      )}
      {currentUser?.id &&
        userId !== currentUser.id &&
        (!!teamRegistered || isCanRegister) && (
          <Button
            variant={'contained'}
            onClick={teamRegistered ? () => openModalTeam() : openModalRegister}
            color={'primary'}
            className={'-registerBtn'}
          >
            {teamRegistered
              ? t('event.eventViewPage.seeTeam')
              : t('event.eventViewPage.registerBtn')}
          </Button>
        )}

      {!currentUser?.id && isCanRegister && (
        <Button
          variant={'contained'}
          onClick={() =>
            authRepository.login(
              window.location.origin + window.location.pathname + '?register=true',
            )
          }
          color={'primary'}
          className={'-registerBtn'}
        >
          {t('event.eventViewPage.registerBtn')}
        </Button>
      )}
    </div>
  );
};
