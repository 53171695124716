import { ThemeProvider } from '@mui/material';
import { UnpyEvent } from 'domain/event/UnpyEvent';
import { PageLayout } from 'primary/Components/PageLayout/PageLayout';
import { EVENT_CONTEXT } from 'primary/context/event/EventContext';
import { EventRegisterParamsForm } from 'primary/events/forms/EventRegisterParamsForm';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useTranslate } from 'primary/hooks/useTranslate';
import React, { useContext } from 'react';

import { themeParameters } from '../parameters.helper';

export const RegisterParams = () => {
  const { event, dispatch } = useContext(EVENT_CONTEXT);
  const { eventRepository } = useContextDependency();
  const t = useTranslate();
  if (!event) return null;

  const onSubmitted = () => {
    eventRepository
      .getEventById(event.id)
      .then((value: UnpyEvent) => dispatch({ type: 'SET_EVENT', event: value }));
  };

  return (
    <div className={'eventAppearence'}>
      <PageLayout>
        <>
          <ThemeProvider theme={themeParameters}>
            <EventRegisterParamsForm event={event} onCreated={onSubmitted} />
          </ThemeProvider>
        </>
      </PageLayout>
    </div>
  );
};
