import './Neon.scss';

import classNames from 'classnames';
import React, { FC } from 'react';

import { NeonClassName } from '../../../domain/neon/Neon';

export type NeonColor = 'blue' | 'violet';

interface NeonProps {
  className?: string;
  variant?: NeonClassName;
  width?: string;
  style?: any;
}

export const Neon: FC<NeonProps> = ({ className, variant = 'default', width, style }) => {
  return (
    <div
      className={classNames('neon')}
      style={width ? { ...style, width: width } : style}
    ></div>
  );
};
