import { Edit, ExitToApp, Remove } from '@mui/icons-material';
import { Button, Hidden } from '@mui/material';
import { StructureMember } from 'domain/structureMember/StructureMember';
import { StructureMemberState } from 'domain/structureMember/StructureMemberState';
import { insertIf } from 'helper/array.helper';
import {
  PopperButton,
  PopperButtonsConf,
} from 'primary/Components/PopperButton/PopperButton';
import { routesConfig } from 'primary/Configs/Routes.config';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useTranslate } from 'primary/hooks/useTranslate';
import React from 'react';
import { useNavigate } from 'react-router-dom';

type StructureMembersDatatableMenuProps = {
  member: StructureMember;
  readonly?: boolean;
  onClickEditMember?: (member: StructureMember) => void;
  onClickDeleteMember?: (member: StructureMember) => void;
  otherMembers?: StructureMember[];
};

export const StructureMembersDatatableMenu = ({
  member,
  readonly,
  onClickEditMember,
  onClickDeleteMember,
  otherMembers,
}: StructureMembersDatatableMenuProps) => {
  const t = useTranslate();
  const navigate = useNavigate();
  const { authRepository } = useContextDependency();
  const isAcceptedMember = member.state === StructureMemberState.ACCEPTED;
  const isWaitingForAccept = [
    StructureMemberState.WAITING_JOIN_ACCEPTED,
    StructureMemberState.WAITINT_LINK_ACCEPTED,
  ].includes(member.state);
  const isWaitingInviteAccept =
    member.state === StructureMemberState.WAITING_INVITE_ACCEPTED;
  const canDeleteMember =
    member?.user?.id != authRepository.currentUser?.id &&
    otherMembers?.find((m) => m.user.id === authRepository.currentUser?.id)?.role.code ===
      'PRES';
  const conf: PopperButtonsConf[] = [
    ...insertIf(isAcceptedMember, {
      label: t('structure.members.datatable.seeProfil'),
      action: () => {
        navigate(routesConfig.userProfil.to(member?.user?.userProfil?.id));
      },
      icon: <ExitToApp />,
    }),
    ...insertIf(isAcceptedMember && !readonly, {
      label: t('structure.members.datatable.editRole'),
      action: () => {
        onClickEditMember && onClickEditMember(member as StructureMember);
      },
      icon: <Edit />,
    }),
    ...insertIf(canDeleteMember, {
      label: t('structure.members.datatable.deleteMember'),
      action: () => {
        onClickDeleteMember && onClickDeleteMember(member as StructureMember);
      },
      icon: <Remove />,
    }),
    ...insertIf(isWaitingForAccept, {
      button: (
        <>
          <Button color={'primary'} variant={'contained'} size={'small'}>
            <Hidden smDown>{t('structure.members.datatable.accept')}</Hidden>
          </Button>
          <Button color={'error'} variant={'outlined'} size={'small'}>
            <Hidden smDown>{t('structure.members.datatable.decline')}</Hidden>
          </Button>
        </>
      ),
    }),
    ...insertIf(isWaitingInviteAccept, {
      button: (
        <>
          <span>{t('structure.members.datatable.inviteWaitingResponse')}</span>;
          <Button
            size={'small'}
            color={'secondary'}
            variant={'contained'}
            onClick={() => {
              onClickDeleteMember && onClickDeleteMember(member as StructureMember);
            }}
          >
            <Remove />
            {t('structure.members.datatable.cancelInvitation')}
          </Button>
        </>
      ),
    }),
  ];

  return (
    <PopperButton
      key={'user-profil-preview'}
      conf={conf}
      popperName={'user-profil-preview'}
      placement={'auto'}
    />
  );
};
