import './InputTextArea.scss';

import { FilledInput, TextareaAutosize, TextareaAutosizeProps } from '@mui/material';
import React, { forwardRef, Ref } from 'react';

import { InputProps, ValueType } from './InputWrapper';

type InputTextAreaProps<T extends ValueType = string> = {
  placeholder?: string;
} & InputProps<T> &
  TextareaAutosizeProps;

export const InputTextArea = forwardRef(
  (props: InputTextAreaProps, ref: Ref<HTMLTextAreaElement>) => {
    return (
      <FilledInput
        className={'-filledInputTextArea'}
        ref={ref}
        key={props.name}
        name={props.name}
        components={{
          // eslint-disable-next-line react/display-name
          Input: InputTextAreaComp,
        }}
        inputProps={props}
      />
    );
  },
);
InputTextArea.displayName = 'InputTextArea';

export const InputTextAreaComp = forwardRef(function InputTextAreaCompForwarded(
  props: InputTextAreaProps,
  ref: Ref<HTMLElement>,
) {
  return (
    <TextareaAutosize
      {...props}
      // @ts-ignore
      ref={ref}
      minRows={3}
    />
  );
});
