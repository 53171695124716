import './ModalRegisterEvent.scss';

import { Lock } from '@mui/icons-material';
import { Button } from '@mui/material';
import { ValidationStringLengthEnum } from 'domain/enums/ValidationStringLengthEnum';
import { EventParticipant } from 'domain/event/participants/EventParticipant';
import { PlayerRoleEnum } from 'domain/event/PlayerRoleEnum';
import { TypeTeamCodeEnum } from 'domain/event/TypeTeamCodeEnum';
import { isAuthorizeFromCountLimit } from 'domain/event/UnpyEvent.func';
import { RolesEnum } from 'domain/security/RolesEnum';
import { EventRegisterFormData } from 'primary/Components/Event/RegisterEventFormStep/FormStepPlayers';
import { LoadingScreen } from 'primary/Components/LoadingScreen/LoadingScreen';
import { UnpyLogoUp } from 'primary/Components/UnpyLogo/UnpyLogoUp';
import { routesConfig } from 'primary/Configs/Routes.config';
import { EventRegisterForm } from 'primary/events/forms/EventRegisterForm';
import { useTranslate, UseTranslateReturn } from 'primary/hooks/useTranslate';
import { useYupValidationResolver } from 'primary/hooks/useYupValidationResolver';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { PlayersNumberByTypeTeams, TypeTeam } from '../../../domain/event/TypeTeam';
import { Title } from '../../Components/Title/Title';
import { useContextDependency } from '../../hooks/useContextDependency';
import { useRetrieveFromDomain } from '../../hooks/useRetrieveFromDomain';

interface ModalRegisterEventProps {
  handleClose: () => void;
  typeTeam: TypeTeam;
  idEvent: number;
  onSubmitted: (eventParticipant?: EventParticipant) => void;
}

//@ts-ignore
const validationSchema = (typeTeam: TypeTeam) => (t: UseTranslateReturn) =>
  yup.object({
    structure: yup.string().when('participationAsStructure', {
      is: (val?: boolean) => !!val,
      then: yup
        .string()
        .max(
          ValidationStringLengthEnum.SMALL,
          t('general.forms.errors.tooLong', {
            maxChar: ValidationStringLengthEnum.SMALL,
          }),
        )
        .required(t('general.forms.errors.required')),
      otherwise: yup.string().nullable(),
    }),
    players: yup.object({
      ...PlayersNumberByTypeTeams[TypeTeamCodeEnum.SOL].reduce(
        (acc, value, currentIndex) => {
          return {
            ...acc,
            [`player-${currentIndex}`]: yup.object({
              idPlayer: yup.string().when(`withLink`, {
                is: (val?: boolean) => !val || val?.toString() === 'false',
                then: yup.string().required(t('general.forms.errors.required')),
                otherwise: yup.string().nullable(),
              }),
              role: yup.mixed().required(t('general.forms.errors.required')),
            }),
          };
        },
        {},
      ),
    }),
  });

const ModalRegisterRandomTeam = ({
  handleClose,
  typeTeam,
  idEvent,
  onSubmitted,
}: ModalRegisterEventProps) => {
  const t = useTranslate();
  const { eventRepository, authRepository, eventParticipantRepository } =
    useContextDependency();
  const resolver = useYupValidationResolver(validationSchema(typeTeam as TypeTeam));

  const form = useForm<EventRegisterFormData>({
    resolver,
    defaultValues: {
      name: '',
      structure: '',
      players: {
        [`player-0`]: {
          idPlayer: authRepository.currentUser?.userProfil?.idPlayer.toString(),
          role: PlayerRoleEnum.CHIEF,
        },
      },
      idEvent: idEvent.toString(),
      participationAsStructure: false,
    },
  });
  const [event] = useRetrieveFromDomain(
    () => eventRepository.getEventById(idEvent),
    undefined,
  );

  const [count, , loading] = useRetrieveFromDomain(
    () => eventParticipantRepository.countEventsParticipationConsume(),
    undefined,
  );
  const isUserUp = authRepository.currentUser?.roles.includes(RolesEnum.USER_UP);
  const isAuthorized = isAuthorizeFromCountLimit(count, isUserUp);
  if (!event) return null;
  return (
    <div className={'modal-register-event'}>
      <Title
        threeQuarter
        bordered
        title={t('event.modal.registerTeamRandom')}
        level={2}
      />
      <div className={'-form'}>
        {loading && <LoadingScreen loading />}
        {!loading && !isAuthorized && (
          <LimiteREgisterEventSubscription handleClose={handleClose} />
        )}
        {event.randomTeams && <p>{t('event.modal.registerTeamRandomNotice')}</p>}
        {!event.randomTeams && event.typeTeam?.code === TypeTeamCodeEnum.SOL && (
          <p>
            {t(
              event.freeRegister
                ? 'event.modal.freeRegisterNoticeSolo'
                : 'event.modal.validationRegisterNoticeSolo',
            )}
          </p>
        )}
        {!event.randomTeams && event.typeTeam?.code !== TypeTeamCodeEnum.SOL && (
          <p>
            {t(
              event.freeRegister
                ? 'event.modal.freeRegisterNotice'
                : 'event.modal.validationRegisterNotice',
            )}
          </p>
        )}
        {!loading && isAuthorized && event && (
          <EventRegisterForm
            eventId={event.id}
            randomTeams
            form={form}
            typeTeam={typeTeam}
            closeModal={handleClose}
            onSubmitted={onSubmitted}
          />
        )}
      </div>
    </div>
  );
};

export default ModalRegisterRandomTeam;

export const LimiteREgisterEventSubscription = ({
  handleClose,
}: {
  handleClose: () => void;
}) => {
  const t = useTranslate();
  const navigate = useNavigate();
  const handleGotOSubscribe = () => {
    handleClose();
    navigate(routesConfig.up.to());
  };
  return (
    <div className={'-limitParticipateEventSubscription'}>
      <Lock className={'lock-icon'} />
      <span>{t('event.limit.participate')}</span>
      <div className={'notice'}>
        <span>{t('event.limit.participateSubscribeNotice')}</span>
        <UnpyLogoUp />
        <span>{t('event.limit.participateSubscribeNotice2')}</span>
      </div>
      <Button
        variant={'contained'}
        onClick={handleGotOSubscribe}
        color={'primary'}
        className={'-subscribeBtn'}
      >
        {t('home.up.subscribe')}
      </Button>
    </div>
  );
};
