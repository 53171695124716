import '../../Utils/Forms/UnpyForm.scss';

import { LoadingButton } from '@mui/lab';
import { Alert, Box, Grid, Switch, TextField } from '@mui/material';
import { ValidationStringLengthEnum } from 'domain/enums/ValidationStringLengthEnum';
import {
  BRACKET_TEAM_NUMBER_OPT,
  DOUBLE_BRACKET_TEAM_NUMBER_OPT,
} from 'domain/event/eventOptByGames';
import { EventStatusEnum } from 'domain/event/EventStatusEnum';
import { TypeEventCodeEnum } from 'domain/event/TypeEventCodeEnum';
import {
  getInitialValueRegisterParams,
  getIsReadOnly,
} from 'domain/event/UnpyEvent.func';
import { formatDate } from 'helper/date.helper';
import { InputSelectMui } from 'primary/Components/Input/InputSelectMui';
import { Title } from 'primary/Components/Title/Title';
import { useBooleanToggle } from 'primary/hooks/useBooleanToggle';
import ModalConfirm from 'primary/ModalConfirm/ModalConfirm';
import React, { FC, useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { IEventRepository } from '../../../domain/event/Event.repository';
import { UnpyEvent } from '../../../domain/event/UnpyEvent';
import { Translate } from '../../../domain/translation/Translation.repository';
import { InputWrapper } from '../../Components/Input/InputWrapper';
import { sendEventToastMessage } from '../../Components/Toast/Toast.helper';
import { useContextDependency } from '../../hooks/useContextDependency';
import { useRetrieveFromDomain } from '../../hooks/useRetrieveFromDomain';
import { useTranslate, UseTranslateReturn } from '../../hooks/useTranslate';
import { useYupValidationResolver } from '../../hooks/useYupValidationResolver';

export type EventRegisterParamsFormData = {
  typeTeam: string;
  maxTeam: number;
  bracketTeamNumber: string;
  randomTeams: boolean;
  startDateTime: string;
  endSubscribeDate: string;
  freeRegister: boolean;
  singleStructureTeam: boolean;
};

const validationSchema = (t: UseTranslateReturn) =>
  yup.object({
    startDateTime: yup
      .string()
      .max(
        ValidationStringLengthEnum.LONG,
        t('general.forms.errors.tooLong', { maxChar: ValidationStringLengthEnum.LONG }),
      )
      .required(t('general.forms.errors.required')),
    endSubscribeDate: yup
      .string()
      .max(
        ValidationStringLengthEnum.LONG,
        t('general.forms.errors.tooLong', { maxChar: ValidationStringLengthEnum.LONG }),
      )
      .required(t('general.forms.errors.required')),
    freeRegister: yup.boolean().nullable(),
    singleStructureTeam: yup.boolean().nullable(),

    typeTeam: yup
      .string()
      .max(
        ValidationStringLengthEnum.DEFAULT,
        t('general.forms.errors.tooLong', { maxChar: ValidationStringLengthEnum.SMALL }),
      )
      .required(t('general.forms.errors.required')),

    maxTeam: yup.string().nullable(),
    bracketTeamNumber: yup.string().nullable(),
  });

export const useOnSubmit =
  (
    eventRepostory: IEventRepository,
    onSubmitted: (id: number) => void,
    setLoading: (value: boolean) => void,
    idEvent: number,
    t: Translate,
  ) =>
  (data: EventRegisterParamsFormData) => {
    setLoading(true);
    eventRepostory
      .updateEventRegisterParams(data, idEvent)
      .then((event) => {
        sendEventToastMessage(
          t('event.parameters.registerParams.updateSuccess'),
          'success',
        );
        onSubmitted(event.id);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  };

interface EventCreateFormProps {
  onCreated: (id: number) => void;
  event: UnpyEvent;
}

export const EventRegisterParamsForm: FC<EventCreateFormProps> = ({
  onCreated,
  event,
}) => {
  const resolver = useYupValidationResolver(validationSchema);
  const initialValues = getInitialValueRegisterParams(event);
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    control,
    watch,
    register,
    setValue,
    getValues,
    formState: { isSubmitting, errors },
  } = useForm<EventRegisterParamsFormData>({
    resolver,
    defaultValues: initialValues,
  });
  const { eventRepository } = useContextDependency();

  const t = useTranslate();
  const onSubmit = useOnSubmit(eventRepository, onCreated, setLoading, event.id, t);

  const [typeTeam] = useRetrieveFromDomain(
    () => eventRepository.getAllTypeTeam(),
    undefined,
  );

  const { field: bracketTeamNumberField } = useController({
    control: control,
    name: 'bracketTeamNumber',
    defaultValue: initialValues.bracketTeamNumber?.toString(),
  });

  const ref = React.createRef<HTMLButtonElement>();

  const [
    isOpenModalConfirmEditStartDate,
    ,
    openModalConfirmEditStartDate,
    closeModalConfirmEditStartDate,
  ] = useBooleanToggle();
  const newStartDateTime = watch('startDateTime');

  const isStartDateTimeDited =
    newStartDateTime !== initialValues.startDateTime &&
    event.status !== EventStatusEnum.DRAFT;

  const submitBtn = () => {
    if (isStartDateTimeDited) {
      openModalConfirmEditStartDate();
    } else {
      ref?.current?.click();
    }
  };

  const { field: typeTeamField } = useController({ control: control, name: 'typeTeam' });
  const isRandomTeams = watch('randomTeams');

  return (
    <Box className={'eventRulesForm unpy-form'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Title
          title={t('event.parameters.registerParams.register.title')}
          level={1}
          compensatePadding
          threeQuarter
          bordered
        />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <InputWrapper
              className={'unpy-form__field'}
              label={t('event.form.labels.endSubscribeDate')}
              errors={errors}
              isSubmitting={isSubmitting}
              noFullWidth
              Input={TextField}
              inputProps={{
                placeholder: t('event.form.placeholders.endSubscribeDate'),
                variant: 'filled',
                ...register('endSubscribeDate', { required: true }),
                disabled: getIsReadOnly(event, 'endSubscribeDate') || isSubmitting,
                type: 'datetime-local',
              }}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputWrapper
              className={'modal-create-event__field'}
              label={t('event.form.labels.typeTeam')}
              errors={errors}
              Input={InputSelectMui}
              isSubmitting={false}
              required
              inputProps={{
                placeholder: t('event.form.placeholders.typeTeam'),
                ...typeTeamField,
                options: typeTeam
                  ? typeTeam.map((typeTeam) => ({
                      label: t(`enum.typeTeamCode.${typeTeam.code}`),
                      value: typeTeam.code,
                    }))
                  : [],
                disabled: getIsReadOnly(event, 'typeTeam') || isSubmitting,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            {event.typeEvent.code === TypeEventCodeEnum.TOURNAMENT && (
              <InputWrapper
                className={'unpy-form__field'}
                label={t('event.form.labels.bracketTeamNumber')}
                errors={errors}
                Input={InputSelectMui}
                isSubmitting={false}
                required
                inputProps={{
                  ...bracketTeamNumberField,
                  options: BRACKET_TEAM_NUMBER_OPT.map((number) => ({
                    label: t(`event.form.labels.bracketTeamNumberOpt`, { count: number }),
                    value: number.toString(),
                  })),
                  disabled: getIsReadOnly(event, 'maxTeam') || isSubmitting,
                }}
              />
            )}
            {event.typeEvent.code === TypeEventCodeEnum.TOURNAMENT_DOUBLE_ELEM && (
              <InputWrapper
                className={'unpy-form__field'}
                label={t('event.form.labels.bracketTeamNumber')}
                errors={errors}
                Input={InputSelectMui}
                isSubmitting={false}
                required
                inputProps={{
                  ...bracketTeamNumberField,
                  options: DOUBLE_BRACKET_TEAM_NUMBER_OPT.map((number) => ({
                    label: t(`event.form.labels.bracketTeamNumberOpt`, { count: number }),
                    value: number.toString(),
                  })),
                  disabled: getIsReadOnly(event, 'maxTeam') || isSubmitting,
                }}
              />
            )}
            {(event.typeEvent.code === TypeEventCodeEnum.LIGUE ||
              event.typeEvent.code === TypeEventCodeEnum.LIG_BR) && (
              <InputWrapper
                errors={errors}
                Input={TextField}
                label={t('event.form.labels.maxTeam')}
                className={'-input'}
                inputProps={{
                  type: 'number',
                  ...register('maxTeam'),
                  disabled: getIsReadOnly(event, 'maxTeam') || isSubmitting,
                  variant: 'filled',
                }}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputWrapper
              label={t('event.form.labels.freeRegister')}
              errors={errors}
              className={'unpy-form__field'}
              isSubmitting={isSubmitting}
              Input={Switch}
              noFullWidth
              inputProps={{
                ...register('freeRegister'),
                defaultChecked: event.freeRegister,
                disabled: getIsReadOnly(event, 'freeRegister') || isSubmitting,
                readOnly: getIsReadOnly(event, 'freeRegister') || isSubmitting,
              }}
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputWrapper
              label={t('event.form.labels.singleStructure')}
              errors={errors}
              className={'unpy-form__field'}
              isSubmitting={isSubmitting}
              Input={Switch}
              noFullWidth
              inputProps={{
                disabled: getIsReadOnly(event, 'singleStructureTeam') || isSubmitting,
                ...register('singleStructureTeam'),
                defaultChecked: event.singleStructureTeam,
              }}
              required
            />
          </Grid>
        </Grid>
        <Title
          title={t('event.parameters.registerParams.starting.title')}
          level={1}
          compensatePadding
          threeQuarter
          bordered
        />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <InputWrapper
              className={'unpy-form__field'}
              label={t('event.form.labels.startDateTime')}
              errors={errors}
              isSubmitting={isSubmitting}
              Input={TextField}
              notice={
                getIsReadOnly(event, 'startDateTime') || isSubmitting
                  ? t('event.form.notice.startDateDisabled')
                  : undefined
              }
              inputProps={{
                placeholder: t('event.form.placeholders.startDateTime'),
                ...register('startDateTime', { required: true }),
                variant: 'filled',
                type: 'datetime-local',
                disabled: getIsReadOnly(event, 'startDateTime') || isSubmitting,
              }}
              required
            />
            {event.delayed && (
              <Alert severity="warning" className={'-alertDelayed'}>
                {t('event.form.notice.delayed', {
                  date: formatDate(event.delayed, 'readable(with hour)'),
                })}
              </Alert>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputWrapper
              label={t('event.form.labels.randomTeams')}
              errors={errors}
              className={'unpy-form__field'}
              isSubmitting={isSubmitting}
              Input={Switch}
              noFullWidth
              inputProps={{
                ...register('randomTeams'),
                defaultChecked: event.randomTeams,
                disabled: getIsReadOnly(event, 'randomTeams') || isSubmitting,
              }}
              required
            />
            {isRandomTeams && (
              <span>
                Les joueurs pourrons s'inscrire seul, les équipes seront généré
                aléatoirement lorsque vous procéderez au démarrage de l'événement{' '}
              </span>
            )}
          </Grid>
        </Grid>
        <div className={'unpy-form__buttons -one'}>
          <button hidden type={'submit'} ref={ref} />
          <LoadingButton
            variant={'contained'}
            color={'primary'}
            loading={loading}
            onClick={submitBtn}
            disabled={
              event.status === EventStatusEnum.FINISH ||
              event.status === EventStatusEnum.CANCELED ||
              loading
            }
          >
            {t('event.form.editBtn')}
          </LoadingButton>
        </div>
      </form>

      <ModalConfirm
        handleClose={closeModalConfirmEditStartDate}
        title={t('event.modal.confirmEditStartDate.title')}
        action={() => ref?.current?.click()}
        isOpen={isOpenModalConfirmEditStartDate}
        description={
          <Alert
            severity="warning"
            sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}
          >
            {t('event.modal.confirmEditStartDate.notice', {
              date: formatDate(
                new Date(getValues('startDateTime')),
                'readable(with hour)',
              ),
            })}
          </Alert>
        }
      />
    </Box>
  );
};
