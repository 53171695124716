import '../../structure/Profil/Appearence.scss';

import { Visibility } from '@mui/icons-material';
import { Button, Grid, ThemeProvider } from '@mui/material';
import { BackgroundImageType } from 'domain/backgroundImage/BackgroundImageType';
import { ValidationStringLengthEnum } from 'domain/enums/ValidationStringLengthEnum';
import { NeonType } from 'domain/neon/NeonType';
import { isUserUp } from 'domain/user/User.helper';
import { IUserProfilRepository } from 'domain/user/UserProfil.repository';
import { PageLayout } from 'primary/Components/PageLayout/PageLayout';
import { Title } from 'primary/Components/Title/Title';
import { BackgroundImageForm } from 'primary/Components/UserAppaearenceForms/BackgroundImageForm';
import { NeonForm } from 'primary/Components/UserAppaearenceForms/NeonForm';
import { ProfilPhotoForm } from 'primary/Components/UserAppaearenceForms/ProfilPhotoForm';
import { useTranslate, UseTranslateReturn } from 'primary/hooks/useTranslate';
import { useYupValidationResolver } from 'primary/hooks/useYupValidationResolver';
import ModalContainer from 'primary/ModalContainer/ModalContainer';
import {
  PreviewEditUserProfil,
  PreviewEditUserProfilPage,
} from 'primary/Parameters/user/Profil/PreviewEditUserProfil';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { themeParameters } from '../../parameters.helper';
import { useUserParameters } from '../useUserParameters.hook';

export type resizePropsFormData = {
  startX: number;
  startY: number;
  endX: number;
  endY: number;
};

export type ProfilAppearenceFormData = {
  fileNameUploaded?: string;
  resizeProps?: resizePropsFormData;
  backgroundImageId?: {
    id: number;
    isUp: boolean;
  };
  neonPhoto?: string;
  neonBar?: string;
  neonLitleBar?: string;
  neonBorder?: string;
};

const validationSchema = (t: UseTranslateReturn, isUp: boolean) =>
  yup.object({
    fileNameUploaded: yup
      .string()
      .max(
        ValidationStringLengthEnum.LONG,
        t('general.forms.errors.tooLong', { maxChar: ValidationStringLengthEnum.LONG }),
      )
      .nullable(),
    backgroundImageId: yup
      .object({
        id: yup.number(),
        isUp: yup.boolean(),
      })
      .test(
        'backgroundIsUp',
        t('user.parameters.appearence.backgroundIsUpError'),
        (value) => {
          if (isUp || !value.isUp) {
            return true;
          }
          return false;
        },
      ),
    neonPhoto: yup
      .string()
      .nullable()
      .test('neonIsUp', t('user.parameters.appearence.neonIsUpError'), (value) => {
        if (isUp || !value) {
          return true;
        }
        return false;
      }),
    neonBorder: yup
      .string()
      .nullable()
      .test('neonIsUp', t('user.parameters.appearence.neonIsUpError'), (value) => {
        if (isUp || !value) {
          return true;
        }
        return false;
      }),
    neonBar: yup
      .string()
      .nullable()
      .test('neonIsUp', t('user.parameters.appearence.neonIsUpError'), (value) => {
        if (isUp || !value) {
          return true;
        }
        return false;
      }),
    neonLitleBar: yup
      .string()
      .nullable()
      .test('neonIsUp', t('user.parameters.appearence.neonIsUpError'), (value) => {
        if (isUp || !value) {
          return true;
        }
        return false;
      }),
  });

export const useOnSubmit =
  (userProfilRepository: IUserProfilRepository, onSubmitted: () => void, file?: File) =>
  (data: ProfilAppearenceFormData) => {
    userProfilRepository.updateUserProfilAppearence(data, file).then(onSubmitted);
  };

export const UserProfilAppearence = () => {
  const { user, onSubmitted, userProfilRepository } = useUserParameters();
  const t = useTranslate();
  const resolver = useYupValidationResolver(validationSchema(t, isUserUp(user)));

  const initialValues = useMemo(() => {
    return {
      fileNameUploaded: user?.userProfil.photo as string,
      backgroundImageId: {
        id: user?.userProfil.backgroundImage?.id,
        isUp: !!user?.userProfil.backgroundImage?.isUp,
      },
      neonPhoto: user?.userProfil.neonPhoto,
      neonBar: user?.userProfil.neonBar,
      neonLitleBar: user?.userProfil.neonLitleBar,
      neonBorder: user?.userProfil.neonBorder,
    };
  }, [user?.userProfil]);

  const form = useForm<ProfilAppearenceFormData>({
    resolver,
    defaultValues: initialValues,
    mode: 'onSubmit',
  });
  const [file, setFile] = useState<File | undefined>();
  const onSubmit = useMemo(
    () => useOnSubmit(userProfilRepository, onSubmitted, file),
    [file],
  );
  const [isPagePreviewOpen, setIsPagePreviewOpen] = useState(false);
  const watchedValues = form.watch();
  console.log('WATCHED VALEUS', watchedValues);
  return (
    <div className={'appearence'}>
      <form
        className={'appearenceForm'}
        name={'appearenceForm'}
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <PageLayout>
          <>
            <Title
              title={t('user.parameters.appearence.title')}
              level={1}
              compensatePadding
              threeQuarter
              bordered
            />
            <ThemeProvider theme={themeParameters}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={5}>
                  <Title
                    title={t('user.parameters.appearence.titlePreview')}
                    level={2}
                    accent
                    className={'-titleAppearence'}
                  />
                  <Button
                    variant={'contained'}
                    color={'primary'}
                    onClick={() => setIsPagePreviewOpen(true)}
                  >
                    <Visibility />
                    <span style={{ color: 'white' }}>Page profil</span>
                  </Button>
                  <h4>{t('previewEditProfil.mercatoView')}</h4>
                  <PreviewEditUserProfil
                    profil={user?.userProfil}
                    draftedValues={{
                      ...watchedValues,
                      backgroundImageId: watchedValues.backgroundImageId,
                    }}
                    draftPhoto={file}
                  />
                  <ModalContainer
                    fullScreen
                    header={{
                      title: 'Prévisualisation de la page profil',
                      withButtonClose: true,
                    }}
                    handleClose={() => setIsPagePreviewOpen(false)}
                    content={
                      <div
                        style={{
                          maxHeight: '80vh',
                          overflowY: 'auto',
                          maxWidth: '80%',
                          overflowX: 'auto',
                        }}
                      >
                        <PreviewEditUserProfilPage
                          profil={user?.userProfil}
                          draftedValues={{
                            ...watchedValues,
                            backgroundImageId: watchedValues.backgroundImageId,
                          }}
                          draftPhoto={file}
                        />
                      </div>
                    }
                    isOpen={isPagePreviewOpen}
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <Grid container spacing={2} direction={'column'}>
                    <Grid item xs={12}>
                      <Title
                        title={t('user.parameters.appearence.titleForm')}
                        level={2}
                        accent
                        className={'-titleAppearence'}
                      />
                    </Grid>
                    <Grid item xs={12} md={12} lg={6} className={'-boxField'}>
                      <NeonForm form={form} type={NeonType.PLAYER} />
                    </Grid>
                    <Grid item xs={12} md={12} lg={6} className={'-boxField'}>
                      <ProfilPhotoForm
                        form={form}
                        onUploadFile={setFile}
                        fileUploaded={file}
                        isDefaultPhoto={user?.userProfil.defaultPhoto}
                        initialPath={user?.userProfil.publicPhotoUrl}
                      />
                    </Grid>
                    <Grid item xs={12} className={'-boxField'}>
                      <BackgroundImageForm
                        form={form}
                        type={BackgroundImageType.PLAYER}
                      />
                    </Grid>
                    <Button
                      color={'primary'}
                      style={{ justifySelf: 'flex-end', marginTop: '16px' }}
                      variant={'contained'}
                      type={'submit'}
                    >
                      {t('parameters.menus.profil.user.form.submit')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </ThemeProvider>
          </>
        </PageLayout>
      </form>
    </div>
  );
};
