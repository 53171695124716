import './UserProfilCard.scss';

import React from 'react';

import { UserProfil } from '../../../../domain/profil/UserProfil';
import { UserProfilFrontContent } from './front/UserProfilFrontContent';

type UserProfilCardProps = {
  profil: UserProfil;
  isPreview?: boolean;
  draftPhoto?: File;
};

export type UserProfilContext = UserProfilCardProps;

export const UserProfilCard = ({
  profil,
  isPreview,
  draftPhoto,
}: UserProfilCardProps) => {
  const styleBackground = !isPreview
    ? {
        backgroundImage: `url("${profil.backgroundImage?.publicUrl}")`,
        backgroundSize: 'cover',
      }
    : {};
  return (
    <div className={'userProfilCard'}>
      <div style={styleBackground} className={'-backgroundUserProfil'}></div>
      <UserProfilFrontContent
        isPreview={isPreview}
        profil={profil}
        draftPhoto={draftPhoto}
      />
    </div>
  );
};
