import './PreviewStructureItem.scss';

import { InfoRounded } from '@mui/icons-material';
import { Chip } from '@mui/material';
import classNames from 'classnames';
import { getNeonBorderStyle } from 'domain/profil/Profil.func';
import { Structure } from 'domain/structure/Structure';
import {
  PopperButton,
  PopperButtonsConf,
} from 'primary/Components/PopperButton/PopperButton';
import { WrapperOtherProfilBannerOverview } from 'primary/Components/User/WrapperOtherProfilBannerOverview';
import { routesConfig } from 'primary/Configs/Routes.config';
import { useTranslate } from 'primary/hooks/useTranslate';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { RootState } from '../../store';
import { useContextDependency } from '../hooks/useContextDependency';

type PreviewStructureItemProps = {
  structure: Structure;
  onClick?: (structure: Structure) => void;
  isSelected?: boolean;
};

export const PreviewStructureItem = ({
  structure,
  onClick,
  isSelected,
}: PreviewStructureItemProps) => {
  const { relationRepository, authRepository } = useContextDependency();
  const user = useSelector((state: RootState) => state.user.connectedUser);
  const member = structure?.members.find(
    (member) =>
      member.user.userProfil.idProfil === authRepository?.currentUser?.userProfil.id,
  );
  const neonPhotoStyle = getNeonBorderStyle(structure.structureProfil?.neonPhoto);

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const t = useTranslate();

  const handleClick = () => {
    if (!structure.structureProfil?.id) return;
    navigate(routesConfig.otherStructureProfil.to(structure.structureProfil.id));
  };

  const conf: PopperButtonsConf[] = useMemo(() => {
    return [
      {
        label: t('profil.userProfilAction.infosMore'),
        action: handleClick,
        icon: <InfoRounded />,
      },
    ];
  }, [structure]);

  return (
    <div
      className={classNames('previewStructureItem', { isSelected: isSelected })}
      onClick={() => onClick?.(structure)}
    >
      <div className={classNames('previewStructureItem__infos')}>
        <div className={'previewStructureItem__infos-photo'}>
          <WrapperOtherProfilBannerOverview
            banner={structure?.structureProfil?.bannerImagePublicUrl}
            className={classNames('-bannerImg')}
            idStructureProfil={structure.structureProfil?.id}
            style={neonPhotoStyle}
          />
        </div>
        <span className={'previewStructureItem__infos-name'}>{structure?.name}</span>
        <Chip
          className={classNames('-tagStructureRoleStatus')}
          label={member?.role?.code ? t(`structure.roles.${member.role.code}`) : ''}
        />
      </div>
      <div className={'previewStructureItem__actions'}>
        <PopperButton
          conf={conf}
          popperName={'structure-item-menu'}
          placement={'bottom-end'}
        />
      </div>
    </div>
  );
};
