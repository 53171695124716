import { SellerAccountConformityEnum } from 'domain/user/SellerAccountConformity.enum';
import { UserProfilView } from 'domain/user/UserProfilView';
import { toEnum } from 'helper/enum.helper';
import {
  RestBackgroundImageView,
  toBackgroundImageView,
} from 'secondary/backgroundImage/RestBackgroundImageView';
import { RestCountry, toCountry } from 'secondary/country/RestCountry';
import { RestGameProfil, toGameProfil } from 'secondary/gameProfil/RestGameProfil';
import { RestPlatform, toPlatform } from 'secondary/platform/RestPlatform';

import { RestNeon, toNeon } from '../neon/RestNeon';

export interface RestUserProfilView {
  id: number;
  idProfil: number;
  idPlayer: string;
  photo: string;
  publicPhotoUrl: string;
  defaultPhoto?: boolean;
  backgroundImage: RestBackgroundImageView;
  comformity?: string;
  neon?: RestNeon;
  country?: RestCountry;
  platforms?: RestPlatform[];
  games?: RestGameProfil[];
  neonPhoto?: string;
  neonBar?: string;
  neonLitleBar?: string;
  neonBorder?: string;
}

export function toUserProfilView(restUserView: RestUserProfilView): UserProfilView {
  const {
    id,
    idProfil,
    country,
    platforms,
    idPlayer,
    publicPhotoUrl,
    photo,
    defaultPhoto,
    backgroundImage,
    comformity,
    neon,
    neonPhoto,
    neonBar,
    neonLitleBar,
    neonBorder,
    games,
  } = restUserView;
  return new UserProfilView(
    id,
    idProfil,
    idPlayer,
    photo || publicPhotoUrl,
    !!defaultPhoto,
    comformity ? toEnum(SellerAccountConformityEnum, comformity) : undefined,
    backgroundImage ? toBackgroundImageView(backgroundImage) : undefined,
    neon ? toNeon(neon) : undefined,
    country ? toCountry(country) : undefined,
    platforms ? platforms.map(toPlatform) : undefined,
    games ? games.map((game) => toGameProfil(game)) : undefined,
    neonPhoto,
    neonBar,
    neonLitleBar,
    neonBorder,
  );
}
