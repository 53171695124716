import './BannerPhotoOverview.scss';

import classNames from 'classnames';
import React, { FC } from 'react';

import { useContextDependency } from '../../hooks/useContextDependency';
import { useRetrieveFromDomain } from '../../hooks/useRetrieveFromDomain';
import { LoadingScreen } from '../LoadingScreen/LoadingScreen';

interface OtherStructureProfilBannerOverviewProps {
  className?: string;
  banner?: string;
  idStructureProfil?: number;
  style?: React.CSSProperties;
}

export const OtherStructureProfilBannerOverview: FC<
  OtherStructureProfilBannerOverviewProps
> = ({ style, banner, className, idStructureProfil }) => {
  const { documentRepostory } = useContextDependency();
  const isDraft = banner?.includes('blob');
  const [profilBanner, , fetching] = useRetrieveFromDomain(
    () =>
      documentRepostory.getStructureProfilBannerOther(
        idStructureProfil as number,
        banner,
      ),
    undefined,
    !!idStructureProfil && !!banner && !banner.startsWith('http'),
  );
  if (fetching) return <LoadingScreen loading={fetching} />;

  return (
    <img
      style={style}
      className={classNames('bannerPhotoOverview', className)}
      src={!!banner || isDraft ? banner : profilBanner?.publicUrl}
      alt={profilBanner?.fileName ?? 'default-banner'}
    />
  );
};
