import './TopBar.scss';

import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Container,
  Drawer,
  Hidden,
  IconButton,
  Toolbar,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import classNames from 'classnames';
import { selectCurrentLanguage } from 'domain/translation/store/TranslationSlice';
import { routesConfig } from 'primary/Configs/Routes.config';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, matchPath, Path, useLocation } from 'react-router-dom';
import { LANG } from 'secondary/translation/i18n.nt';

import { RootState } from '../../../store';
import { useContextDependency } from '../../hooks/useContextDependency';
import { useTranslate } from '../../hooks/useTranslate';
import { UnpyLogo } from '../UnpyLogo/UnpyLogo';
import { LoggedInUserAvatarMenu } from '../User/LoggedInUserAvatarMenu';

type LabelHomeTopBar =
  | undefined
  | 'topbar.links.home'
  | 'topbar.links.event'
  | 'topbar.links.marketplace'
  | 'topbar.links.mercatoPlayer'
  | 'topbar.links.mercatoStructure'
  | 'topbar.links.mercato'
  | 'topbar.links.up';

export type confNavBar = {
  label: LabelHomeTopBar;
  link: string;
  icon?: string;
  path?: Path;
  subPaths?: confNavBar[];
};

type NavBarProps = {
  confs: confNavBar[];
  loadingLogging?: boolean;
  isConnected: boolean;
  socketReady: boolean;
  isLoadingAccount: boolean;
};

function casti18NLangCodeToLangValue(language: string) {
  switch (language) {
    case 'fr':
      return LANG.FR;
    case 'en':
      return LANG.EN;
    case 'en_EN':
      return LANG.EN;
    case 'fr_FR':
      return LANG.FR;
    default:
      return LANG.FR;
  }
}

export const TopBar: FC<NavBarProps> = ({
  isLoadingAccount,
  confs,
  isConnected,
  socketReady,
}) => {
  const { authRepository } = useContextDependency();
  const user = useSelector((state: RootState) => state.user.connectedUser);
  const t = useTranslate();
  const { pathname } = useLocation();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const currentLanguage = useSelector(selectCurrentLanguage);
  const dispatch = useDispatch();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const container = window !== undefined ? () => window.document.body : undefined;

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{ textAlign: 'left' }}
      className={'topbar__drawer'}
    >
      <div className={'topbar__toolbar-logoContainer -logoContainer'}>
        <UnpyLogo size={'big'} />
      </div>
      <nav className={classNames('topbar__navbarMobile')} id={'main-nav-bar'}>
        <ul>
          {confs.map((conf: confNavBar) => (
            <MenuItem
              key={conf.label}
              className={classNames('topbar__navbarMobile-link', {
                '-active': matchPath(
                  {
                    path: conf.path as unknown as string,
                    end: false,
                  },
                  pathname,
                ),
                '-icon': !!conf.icon,
              })}
            >
              {!!conf.subPaths && (
                <div className={'subPaths'}>
                  {conf.subPaths.map((subConf) => (
                    <MenuItem
                      key={subConf.label}
                      className={classNames('topbar__navbarMobile-link', {
                        '-active': matchPath(
                          {
                            path: subConf.path as unknown as string,
                            end: false,
                          },
                          pathname,
                        ),
                        '-icon': !!subConf.icon,
                      })}
                    >
                      <Link key={subConf.link} to={subConf.link}>
                        {subConf.label && t(subConf.label)}
                        {!!subConf.icon && (
                          <img
                            alt={'logo-unpy-up'}
                            src={conf.icon}
                            className={'logo-up -logo'}
                          />
                        )}
                      </Link>
                    </MenuItem>
                  ))}
                </div>
              )}
              {!conf.subPaths && (
                <Link key={conf.link} to={conf.link}>
                  {conf.label && t(conf.label)}
                  {!!conf.icon && (
                    <img
                      alt={'logo-unpy-up'}
                      src={conf.icon}
                      className={'logo-up -logo'}
                    />
                  )}
                </Link>
              )}
            </MenuItem>
          ))}
        </ul>
      </nav>
    </Box>
  );

  return (
    <>
      <AppBar
        position="fixed"
        className={classNames('topbar', {
          '-neonOrange': pathname?.includes('home'),
          '-neonBlue': pathname?.includes('mercato'),
          '-neonGreen': pathname?.includes('event'),
        })}
      >
        <Container maxWidth="xl" className={'topbar__container'}>
          <Toolbar disableGutters className={'topbar__toolbar'}>
            <div className={'topbar__toolbar-logoContainer'}>
              <Hidden smDown>
                <UnpyLogo size={'big'} />
              </Hidden>
            </div>
            <Box
              sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex' } }}
              className={'topbar__toolbar-nav'}
            >
              <nav className={classNames('topbar__navbar')} id={'main-nav-bar'}>
                <ul>
                  {confs.map((conf: confNavBar) => (
                    <>
                      {!conf.subPaths && (
                        <Link
                          className={classNames('topbar__navbar-link', {
                            '-active': matchPath(
                              {
                                path: conf.path as unknown as string,
                                end: false,
                              },
                              pathname,
                            ),
                            '-icon': !!conf.icon,
                          })}
                          key={conf.link}
                          to={conf.link}
                        >
                          {conf.label && t(conf.label)}
                          {!!conf.icon && (
                            <img
                              alt={'logo-unpy-up'}
                              src={conf.icon}
                              className={'logo-up -logo'}
                            />
                          )}
                        </Link>
                      )}
                      {conf.subPaths && (
                        <div
                          className={classNames('-subConfLinkContainer', {
                            '-active': matchPath(
                              {
                                path: conf.path as unknown as string,
                                end: false,
                              },
                              pathname,
                            ),
                          })}
                        >
                          <Link
                            className={classNames('topbar__navbar-link', {
                              '-active': matchPath(
                                {
                                  path: conf.path as unknown as string,
                                  end: false,
                                },
                                pathname,
                              ),
                              '-icon': !!conf.icon,
                            })}
                            key={conf.link}
                            to={conf.link}
                          >
                            {!!conf.icon && (
                              <img
                                alt={'logo-unpy-up'}
                                src={conf.icon}
                                className={'logo-up -logo'}
                              />
                            )}
                            {conf.label && t(conf.label)}
                          </Link>
                          <ul>
                            {conf.subPaths.map((subConf) => (
                              <Link
                                className={classNames('topbar__navbar-link', '-subLink', {
                                  '-active': matchPath(
                                    {
                                      path: subConf.path as unknown as string,
                                      end: false,
                                    },
                                    pathname,
                                  ),
                                  '-icon': !!subConf.icon,
                                })}
                                key={subConf.link}
                                to={subConf.link}
                              >
                                {!!subConf.icon && (
                                  <img
                                    alt={'logo-unpy-up'}
                                    src={subConf.icon}
                                    className={'logo-up -logo'}
                                  />
                                )}
                                {subConf.label && t(subConf.label)}
                              </Link>
                            ))}
                          </ul>
                        </div>
                      )}
                    </>
                  ))}
                </ul>
              </nav>
            </Box>
            <Box
              sx={{ flexGrow: 1, display: { xs: 'flex', sm: 'none' } }}
              className={'topbar__toolbar-mobileNav'}
            >
              <IconButton
                size="large"
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { smd: 'none' } }}
              >
                <MenuIcon />
              </IconButton>
            </Box>
            {isLoadingAccount && (
              <div className={'topbar__toolbar-user -loader'}>
                <CircularProgress color={'info'} size={20} style={{ margin: 'auto' }} />
              </div>
            )}
            {!isLoadingAccount && isConnected && user && (
              <div className={'topbar__toolbar-user'}>
                <LoggedInUserAvatarMenu user={user} socketReady={socketReady} />
              </div>
            )}
            {!isLoadingAccount && !isConnected && (
              <div className={'topbar__toolbar-anonymeButtons'}>
                <Button
                  onClick={() =>
                    authRepository.login(
                      `${window.location.origin}/${routesConfig.activities.path}`,
                    )
                  }
                  variant={'contained'}
                  color={'primary'}
                >
                  {t('topbar.anonyme.login')}
                </Button>
              </div>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xm: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 'auto' },
        }}
      >
        {drawer}
      </Drawer>
    </>
  );
};
