import { Button, Hidden } from '@mui/material';
import classNames from 'classnames';
import { getNeonBorderStyle } from 'domain/profil/Profil.func';
import {
  selectConnectedUserBlocked,
  selectConnectedUserFriend,
} from 'domain/relation/store/RelationSlice';
import {
  PopperButton,
  PopperButtonsConf,
} from 'primary/Components/PopperButton/PopperButton';
import { ActionButtonAddFriend } from 'primary/Components/Profils/User/ActionButton/ActionButtonAddFriend';
import { ActionButtonBlock } from 'primary/Components/Profils/User/ActionButton/ActionButtonBlock';
import { ActionButtonInviteEvent } from 'primary/Components/Profils/User/ActionButton/ActionButtonInviteEvent';
import { ActionButtonInviteStructure } from 'primary/Components/Profils/User/ActionButton/ActionButtonInviteStructure';
import { ActionButtonRemoveFriend } from 'primary/Components/Profils/User/ActionButton/ActionButtonRemoveFriend';
import { ActionButtonSendMessage } from 'primary/Components/Profils/User/ActionButton/ActionButtonSendMessage';
import { ActionButtonUnBlock } from 'primary/Components/Profils/User/ActionButton/ActionButtonUnBlock';
import { LinkSocialNetwork } from 'primary/Components/SocialNetworks/LinkSocialNetwork';
import { routesConfig } from 'primary/Configs/Routes.config';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { UserProfil } from '../../../../../domain/profil/UserProfil';
import { useTranslate } from '../../../../hooks/useTranslate';
import { socialNetworkList } from '../../../../network/network';
import { TeamCreatingAction } from '../TeamCreatingActions';

type ProfilNetworkPingProps = {
  profil: UserProfil;
};

export const useProfilPingBtn = (profil: UserProfil) => {
  const friends = useSelector(selectConnectedUserFriend);
  const block = useSelector(selectConnectedUserBlocked);
  const { authRepository } = useContextDependency();
  const navigate = useNavigate();
  const neonBorderStyle = getNeonBorderStyle(profil.neonLitleBar);
  const handleGoParam = () => {
    navigate(routesConfig.parametersUser.to());
  };
  const alreadyBlocked = block?.find((b) => b.invited.idProfil === profil.id);
  const alreadyFriend = friends?.find(
    (f) => f.invited.idProfil === profil.id || f.owner.idProfil === profil.id,
  );
  const isDisabled = !authRepository?.currentUser?.userProfil.id;
  const isOwn = authRepository?.currentUser?.userProfil.id === profil.id;
  const conf: PopperButtonsConf[] = [
    {
      button: alreadyFriend ? (
        <ActionButtonRemoveFriend profil={profil} withIcon />
      ) : (
        <ActionButtonAddFriend profil={profil} withIcon />
      ),
    },
    {
      button: <ActionButtonSendMessage profil={profil} withIcon />,
    },
    {
      button: <ActionButtonInviteStructure profil={profil} withIcon />,
    },
    {
      button: <ActionButtonInviteEvent profil={profil} withIcon />,
    },
    {
      button: alreadyBlocked ? (
        <ActionButtonUnBlock profil={profil} withIcon />
      ) : (
        <ActionButtonBlock profil={profil} withIcon />
      ),
    },
  ];

  return {
    handleGoParam,
    isOwn,
    conf,
    neonBorderStyle,
    isDisabled,
  };
};

export const ProfilNetworkPing = ({ profil }: ProfilNetworkPingProps) => {
  const t = useTranslate();

  const { isDisabled, handleGoParam, isOwn, conf, neonBorderStyle } =
    useProfilPingBtn(profil);

  return (
    <div className={'userProfilNetworkPing'}>
      <div className={'-networks'}>
        {socialNetworkList.map((network) => (
          <LinkSocialNetwork
            key={network.name}
            network={network}
            value={profil[network.value]}
            big
            variant={'outlined'}
          />
        ))}
      </div>
      <Hidden smDown>
        <div className={'-ping'}>
          {isOwn && (
            <Button
              disabled={isDisabled}
              className={classNames('-pingBtn')}
              style={neonBorderStyle}
              onClick={handleGoParam}
            >
              <span>{t('profil.details.parameters')}</span>
            </Button>
          )}
          {!isOwn && (
            <div>
              <PopperButton
                conf={conf}
                popperName={'user-profil-preview'}
                placement={'auto'}
                button={(togglePopper, ref) => (
                  <Button
                    ref={ref}
                    className={classNames('-pingBtn')}
                    style={neonBorderStyle}
                    onClick={togglePopper}
                  >
                    <span>{t('profil.details.pingBtn')}</span>
                  </Button>
                )}
              />
              <TeamCreatingAction profil={profil} expanded />
            </div>
          )}
        </div>
      </Hidden>
    </div>
  );
};
