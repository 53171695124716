import './EventAdmin.scss';

import { Button, Collapse, TextField, ThemeProvider } from '@mui/material';
import { ValidationStringLengthEnum } from 'domain/enums/ValidationStringLengthEnum';
import { EventStatusEnum } from 'domain/event/EventStatusEnum';
import { InputSelectMui } from 'primary/Components/Input/InputSelectMui';
import { useRetrieveFromDomain } from 'primary/hooks/useRetrieveFromDomain';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { IEventRepository } from '../../../../domain/event/Event.repository';
import { BRACKET_TEAM_NUMBER_OPT } from '../../../../domain/event/eventOptByGames';
import { SystemPointsEnum } from '../../../../domain/event/SystemPoints.enum';
import { TypeEventCodeEnum } from '../../../../domain/event/TypeEventCodeEnum';
import { GAME_ACTIVE_LIST } from '../../../../domain/game/GameActiveList';
import { InputWrapper } from '../../../Components/Input/InputWrapper';
import { PageLayout } from '../../../Components/PageLayout/PageLayout';
import { Title } from '../../../Components/Title/Title';
import { useContextDependency } from '../../../hooks/useContextDependency';
import { useTranslate, UseTranslateReturn } from '../../../hooks/useTranslate';
import { useYupValidationResolver } from '../../../hooks/useYupValidationResolver';
import { themeParameters } from '../../parameters.helper';

export type FixtureEventFormData = {
  startDateTime: string;
  name: string;
  game: string;
  teamNumber: string;
  systemPoints: string;
  typeEvent: string;
  status: string;
  typeTeam: string;
};

const validationSchema = (t: UseTranslateReturn) =>
  yup.object({
    startDateTime: yup
      .string()
      .max(
        ValidationStringLengthEnum.LONG,
        t('general.forms.errors.tooLong', { maxChar: ValidationStringLengthEnum.LONG }),
      )
      .required(t('general.forms.errors.required')),
    name: yup
      .string()
      .max(
        ValidationStringLengthEnum.DEFAULT,
        t('general.forms.errors.tooLong', {
          maxChar: ValidationStringLengthEnum.DEFAULT,
        }),
      )
      .required(t('general.forms.errors.required')),
    game: yup
      .string()
      .max(
        ValidationStringLengthEnum.SMALL,
        t('general.forms.errors.tooLong', { maxChar: ValidationStringLengthEnum.SMALL }),
      )
      .required(t('general.forms.errors.required')),
    teamNumber: yup.number().required(t('general.forms.errors.required')),
    typeEvent: yup
      .string()
      .max(
        ValidationStringLengthEnum.DEFAULT,
        t('general.forms.errors.tooLong', { maxChar: ValidationStringLengthEnum.SMALL }),
      )
      .required(t('general.forms.errors.required')),
    typeTeam: yup
      .string()
      .max(
        ValidationStringLengthEnum.DEFAULT,
        t('general.forms.errors.tooLong', { maxChar: ValidationStringLengthEnum.SMALL }),
      )
      .required(t('general.forms.errors.required')),
    systemPoints: yup
      .string()
      .max(
        ValidationStringLengthEnum.SMALL,
        t('general.forms.errors.tooLong', { maxChar: ValidationStringLengthEnum.SMALL }),
      )
      .required(t('general.forms.errors.required')),
  });

export const useOnSubmit =
  (
    eventRepostory: IEventRepository,
    onSubmitted: (id: number) => void,
    setIsSubmitting: Dispatch<SetStateAction<boolean>>,
  ) =>
  (data: FixtureEventFormData[]) => {
    setIsSubmitting(true);
    eventRepostory
      .createFixtures(data)
      .then((event) => onSubmitted(event.id))
      .finally(() => setIsSubmitting(false));
  };

export const EventsAdmin = () => {
  const [dataToSend, setDataToSend] = useState<FixtureEventFormData[]>([]);

  const onAdd = (data: FixtureEventFormData) => {
    setDataToSend([...dataToSend, data]);
  };

  const { eventRepository, productRepository } = useContextDependency();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const onSubmit = useOnSubmit(
    eventRepository,
    () => console.log('EVENTS GENERATED'),
    setIsSubmitting,
  );
  const t = useTranslate();
  return (
    <PageLayout>
      <>
        <Title
          title={t('user.parameters.eventsAdmin.title')}
          subTitle={t('user.parameters.eventsAdmin.subTitleFormFixture')}
          level={1}
          compensatePadding
          threeQuarter
          bordered
        />
      </>
      <ThemeProvider theme={themeParameters}>
        <FormGenerateEvent onAdd={onAdd} />
        <Button
          variant={'contained'}
          color={'primary'}
          disabled={dataToSend.length === 0 || isSubmitting}
          onClick={() => onSubmit(dataToSend)}
        >
          {t('user.parameters.admins.event.fixture.createAllEvent', {
            count: dataToSend.length,
          })}
        </Button>
        <Button
          variant={'contained'}
          color={'primary'}
          onClick={() => productRepository.sync()}
        >
          Synchroniser les produits stripe
        </Button>
      </ThemeProvider>
    </PageLayout>
  );
};

type FormGenerateEventProps = {
  onAdd: (toAdd: FixtureEventFormData) => void;
};
const FormGenerateEvent = ({ onAdd }: FormGenerateEventProps) => {
  const resolver = useYupValidationResolver(validationSchema);
  const {
    watch,
    register,
    handleSubmit,
    reset,
    control,
    getValues,
    formState: { errors },
  } = useForm<FixtureEventFormData>({ resolver });
  const t = useTranslate();
  const { eventRepository } = useContextDependency();
  const { field: gameField } = useController({ control: control, name: 'game' });
  const [typeTeam] = useRetrieveFromDomain(
    () => eventRepository.getAllTypeTeam(),
    undefined,
  );
  const { field: systemPointField } = useController({
    control: control,
    name: 'systemPoints',
  });
  const { field: typeEventField } = useController({
    control: control,
    name: 'typeEvent',
  });

  const { field: typeTeamField } = useController({
    control: control,
    name: 'typeTeam',
  });

  const { field: bracketTeamNumberField } = useController({
    control: control,
    name: 'teamNumber',
  });
  const { field: statusField } = useController({
    control: control,
    name: 'status',
  });
  const gameSelected = getValues('game');
  const typeEventSelected = watch('typeEvent');
  const collapseOpen = !!gameSelected && !!typeEventSelected;

  return (
    <form
      onSubmit={handleSubmit((values) => {
        onAdd(values);
        reset();
      })}
      className={'event-admin'}
    >
      <div className={'event-admin__column'}>
        <InputWrapper
          className={'event-admin__field'}
          label={t('event.form.labels.name')}
          errors={errors}
          isSubmitting={false}
          Input={TextField}
          inputProps={{
            placeholder: t('event.form.placeholders.name'),
            ...register('name', { required: true }),
            variant: 'filled',
          }}
          required
        />
        <InputWrapper
          className={'event-admin__field'}
          label={t('event.form.labels.startDateTime')}
          errors={errors}
          isSubmitting={false}
          Input={TextField}
          inputProps={{
            placeholder: t('event.form.placeholders.startDateTime'),
            ...register('startDateTime', { required: true }),
            type: 'datetime-local',
            variant: 'filled',
          }}
          required
        />
        <InputWrapper
          accent
          className={'unpy-form__field'}
          label={t('event.filters.status')}
          errors={{}}
          Input={InputSelectMui}
          isSubmitting={false}
          inputProps={{
            ...statusField,
            options: Object.values(EventStatusEnum).map((status) => ({
              value: status,
              label: t(`enum.eventStatus.${status as EventStatusEnum}`),
            })),
          }}
        />
      </div>
      <div className={'event-admin__column'}>
        <InputWrapper
          className={'event-admin__field'}
          label={t('event.form.labels.game')}
          errors={errors}
          Input={InputSelectMui}
          isSubmitting={false}
          required
          inputProps={{
            placeholder: t('event.form.placeholders.game'),
            ...gameField,
            options: Object.values(GAME_ACTIVE_LIST).map((game) => ({
              label: t(`games.libelle.${game}`),
              value: game,
            })),
          }}
        />
        <InputWrapper
          className={'event-admin__field'}
          label={t('event.form.labels.typeEvent')}
          errors={errors}
          Input={InputSelectMui}
          isSubmitting={false}
          required
          inputProps={{
            placeholder: t('event.form.placeholders.typeEvent'),
            ...typeEventField,
            options: Object.values(TypeEventCodeEnum).map((typeEvent) => ({
              label: t(`enum.typeEventCode.${typeEvent}`),
              value: typeEvent,
            })),
          }}
        />

        <InputWrapper
          className={'modal-create-event__field'}
          label={t('event.form.labels.typeTeam')}
          errors={errors}
          Input={InputSelectMui}
          isSubmitting={false}
          required
          inputProps={{
            placeholder: t('event.form.placeholders.typeTeam'),
            ...typeTeamField,
            options: typeTeam
              ? typeTeam.map((typeTeam) => ({
                  label: t(`enum.typeTeamCode.${typeTeam.code}`),
                  value: typeTeam.code,
                }))
              : [],
          }}
        />
        <InputWrapper
          className={'unpy-form__field'}
          label={t('event.form.labels.systemPoints')}
          errors={errors}
          Input={InputSelectMui}
          isSubmitting={false}
          required
          inputProps={{
            placeholder: t('event.form.placeholders.game'),
            ...systemPointField,
            options: Object.values(SystemPointsEnum).map((systemPoint) => ({
              label: t(`enum.systemPoints.${systemPoint}`),
              value: systemPoint,
            })),
          }}
        />
        <Collapse in={collapseOpen}>
          {collapseOpen && typeEventSelected === TypeEventCodeEnum.TOURNAMENT && (
            <InputWrapper
              className={'event-admin__field'}
              label={t('event.form.labels.bracketTeamNumber')}
              errors={errors}
              Input={InputSelectMui}
              isSubmitting={false}
              required
              inputProps={{
                placeholder: t('event.form.labels.bracketTeamNumber'),
                ...bracketTeamNumberField,
                options: BRACKET_TEAM_NUMBER_OPT.map((number) => ({
                  label: t(`event.form.labels.bracketTeamNumberOpt`, {
                    count: number,
                  }),
                  value: number.toString(),
                })),
              }}
            />
          )}
          {collapseOpen &&
            (typeEventSelected === TypeEventCodeEnum.LIGUE ||
              typeEventSelected === TypeEventCodeEnum.LIG_BR) && (
              <InputWrapper
                errors={errors}
                Input={TextField}
                label={t('event.form.labels.bracketTeamNumber')}
                className={'-input'}
                inputProps={{
                  type: 'number',
                  disabled: false,
                  ...register('teamNumber'),
                  variant: 'filled',
                }}
              />
            )}
        </Collapse>
      </div>
      <div className={'event-admin__buttons'}>
        <Button variant={'contained'} color={'primary'} type={'submit'} className={'btn'}>
          {t('user.parameters.admins.event.fixture.createBtn')}
        </Button>
      </div>
    </form>
  );
};
