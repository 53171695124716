import { UnpySubscriptionStatus } from 'domain/payment/UnpySubscriptionStatus';
import { RolesEnum } from 'domain/security/RolesEnum';
import { User } from 'domain/user/User';

export const isUserUp = (user?: User) => {
  if (!user?.subscription) return false;
  return (
    user.subscription.status === UnpySubscriptionStatus.ACTIVE &&
    user.subscription.currentPeriodEnd > new Date()
  );
};

export const isUserUpPremium = (user?: User) => {
  if (!user?.roles?.includes(RolesEnum.USER_UP_PREMIUM)) return false;
  return isUserUp(user);
};
