import '../../Utils/Forms/UnpyForm.scss';

import { LoadingButton } from '@mui/lab';
import { Alert, Box, Button, Grid, Stack } from '@mui/material';
import { ValidationStringLengthEnum } from 'domain/enums/ValidationStringLengthEnum';
import { SystemPointsEnum } from 'domain/event/SystemPoints.enum';
import {
  canEditField,
  getInitialValueParameterize,
  getIsReadOnly,
} from 'domain/event/UnpyEvent.func';
import { InputSelectMui } from 'primary/Components/Input/InputSelectMui';
import { InputTextArea } from 'primary/Components/Input/InputTextArea';
import { sendEventToastMessage } from 'primary/Components/Toast/Toast.helper';
import { EventPublishFormData } from 'primary/events/forms/EventPublishForm';
import { useFetch } from 'primary/hooks/useFetch';
import { useRetrieveFromDomain } from 'primary/hooks/useRetrieveFromDomain';
import React, { FC, useState } from 'react';
import { useController, useForm, UseFormSetValue } from 'react-hook-form';
import * as yup from 'yup';

import { IEventRepository } from '../../../domain/event/Event.repository';
import { UnpyEvent } from '../../../domain/event/UnpyEvent';
import { GAME_ACTIVE_LIST } from '../../../domain/game/GameActiveList';
import {
  ChangeHandlerTypeMeta,
  InputWrapper,
  Option,
} from '../../Components/Input/InputWrapper';
import { useContextDependency } from '../../hooks/useContextDependency';
import { useTranslate, UseTranslateReturn } from '../../hooks/useTranslate';
import { useYupValidationResolver } from '../../hooks/useYupValidationResolver';

export type EventParameterizeFormData = {
  platforms?: string[];
  game: string;
  rules: string;
  systemPoints: string;
  typeEvent: string;
};

const validationSchema = (t: UseTranslateReturn) =>
  yup.object({
    rules: yup
      .string()
      .nullable()
      .max(
        ValidationStringLengthEnum.XXL,
        t('general.forms.errors.tooLong', { maxChar: ValidationStringLengthEnum.XXL }),
      )
      .required(t('general.forms.errors.required')),
    typeEvent: yup
      .string()
      .max(
        ValidationStringLengthEnum.DEFAULT,
        t('general.forms.errors.tooLong', { maxChar: ValidationStringLengthEnum.SMALL }),
      )
      .required(t('general.forms.errors.required')),
    systemPoints: yup
      .string()
      .max(
        ValidationStringLengthEnum.DEFAULT,
        t('general.forms.errors.tooLong', { maxChar: ValidationStringLengthEnum.SMALL }),
      )
      .required(t('general.forms.errors.required')),
    platforms: yup.array().nullable(),
    game: yup
      .string()
      .max(
        ValidationStringLengthEnum.SMALL,
        t('general.forms.errors.tooLong', { maxChar: ValidationStringLengthEnum.SMALL }),
      )
      .required(t('general.forms.errors.required')),
  });

export const useOnSubmit =
  (
    t: UseTranslateReturn,
    eventRepostory: IEventRepository,
    onSubmitted: (id: number) => void,
    idEvent: number,
    setLoading: (value: boolean) => void,
  ) =>
  (data: EventParameterizeFormData) => {
    setLoading(true);
    eventRepostory
      .updateEventParameterize(data, idEvent)
      .then((event) => {
        sendEventToastMessage(t('general.edit.success'), 'success');
        onSubmitted(event.id);
        setLoading(false);
      })
      .finally(() => setLoading(false));
  };

interface EventCreateFormProps {
  onCreated: (id: number) => void;
  event: UnpyEvent;
}

const _changeHandlerPlatforms =
  (setValue: UseFormSetValue<EventPublishFormData>) => (event: ChangeHandlerTypeMeta) => {
    // @ts-ignore
    setValue('platforms', event.target.value as Option<string, object>[]);
  };

export const EventParameterizeForm: FC<EventCreateFormProps> = ({ onCreated, event }) => {
  const resolver = useYupValidationResolver(validationSchema);
  const t = useTranslate();
  const initialValues = getInitialValueParameterize(t, event);

  const {
    handleSubmit,
    control,
    register,
    watch,
    getValues,
    setValue,
    formState: { isSubmitting, errors },
  } = useForm<EventParameterizeFormData>({
    resolver,
    //@ts-ignore
    defaultValues: initialValues,
    mode: 'all',
  });
  const { eventRepository, authRepository } = useContextDependency();
  const [loading, setLoading] = useState(false);
  const [typeEvent] = useRetrieveFromDomain(
    () => eventRepository.getAllTypeEvent(),
    undefined,
  );
  const onSubmit = useOnSubmit(t, eventRepository, onCreated, event.id, setLoading);

  const { platformRepository } = useContextDependency();

  const { field: typeEventField } = useController({
    control: control,
    name: 'typeEvent',
    defaultValue: initialValues.typeEvent,
  });
  const { field: systemPointField } = useController({
    control: control,
    name: 'systemPoints',
    defaultValue: initialValues.systemPoints,
  });

  const { field: rulesControl } = useController({ control: control, name: 'rules' });

  const [platforms] = useFetch(() => platformRepository.getAll(), undefined);

  const { field: gameField } = useController({ control: control, name: 'game' });

  const { field: platformsField } = useController({
    control: control,
    name: 'platforms',
  });

  return (
    <Box className={'unpy-form'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <InputWrapper
              className={'unpy-form__field'}
              label={t('event.form.labels.game')}
              errors={errors}
              Input={InputSelectMui}
              isSubmitting={false}
              required
              inputProps={{
                placeholder: t('event.form.placeholders.game'),
                ...gameField,
                options: Object.values(GAME_ACTIVE_LIST).map((game) => ({
                  label: t(`games.libelle.${game}`),
                  value: game,
                })),
                disabled: !canEditField('game', event),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputWrapper
              label={t('parameters.menus.profil.user.form.platformLabel')}
              errors={errors}
              inputClassName={'input-user-details '}
              labelClassName={'label-user-details '}
              Input={InputSelectMui}
              isSubmitting={isSubmitting}
              //@ts-ignore
              inputProps={{
                placeholder: t('parameters.menus.profil.user.form.platformPlaceholder'),
                ...platformsField,
                options: platforms?.map((platform) => ({
                  label: platform.libelle,
                  value: platform.code.toString(),
                })),
                // @ts-ignore
                onChange: _changeHandlerPlatforms(setValue),
                multiple: true,
                disabled: !canEditField('platforms', event),
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputWrapper
              className={'unpy-form__field'}
              label={t('event.form.labels.typeEvent')}
              errors={errors}
              Input={InputSelectMui}
              isSubmitting={false}
              required
              inputProps={{
                placeholder: t('event.form.placeholders.typeEvent'),
                ...typeEventField,
                options: typeEvent
                  ? typeEvent.map((typeEvent) => ({
                      label: t(`enum.typeEventCode.${typeEvent.code}`),
                      value: typeEvent.code,
                    }))
                  : [],
                disabled: getIsReadOnly(event, 'typeEvent') || isSubmitting,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputWrapper
              className={'unpy-form__field'}
              label={t('event.form.labels.systemPoints')}
              errors={errors}
              Input={InputSelectMui}
              isSubmitting={false}
              required
              inputProps={{
                ...systemPointField,
                options: Object.values(SystemPointsEnum).map((systemPoint) => ({
                  label: t(`enum.systemPoints.${systemPoint}`),
                  value: systemPoint,
                  disabled: getIsReadOnly(event, 'pointSystem') || isSubmitting,
                })),
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputWrapper
              className={'modal-create-event__field'}
              label={t('event.form.labels.rulesAndDesc')}
              errors={errors}
              Input={InputTextArea}
              isSubmitting={false}
              required
              inputProps={{
                placeholder: t('event.form.placeholders.rules'),
                ...rulesControl,
                disabled: getIsReadOnly(event, 'rules') || isSubmitting,
                minRows: 3,
              }}
            />
          </Grid>
        </Grid>
        <div className={'unpy-form__buttons -one'}>
          <LoadingButton
            className={'btn'}
            variant={'contained'}
            color={'primary'}
            onClick={handleSubmit(onSubmit)}
            loading={loading}
            disabled={loading}
          >
            {t('event.form.editBtn')}
          </LoadingButton>
        </div>
      </form>
    </Box>
  );
};

type ActionAlertsProps = {
  message: string;
  actionMessage: string;
  action: () => void;
};

export default function ActionAlerts({
  message,
  action,
  actionMessage,
}: ActionAlertsProps) {
  return (
    <Stack
      sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}
      spacing={2}
    >
      <Alert
        severity="warning"
        sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}
        action={
          <Button color="inherit" size="small" onClick={action}>
            {actionMessage}
          </Button>
        }
      >
        {message}
      </Alert>
    </Stack>
  );
}
