import { addDays, format, isAfter, isBefore } from 'date-fns';
import { CountConsume } from 'domain/event/CountConsume';
import { EventStatusEnum, EventSubStatusEnum } from 'domain/event/EventStatusEnum';
import { EventParticipant } from 'domain/event/participants/EventParticipant';
import { TypeEventCodeEnum } from 'domain/event/TypeEventCodeEnum';
import { PlayersNumberByTypeTeams } from 'domain/event/TypeTeam';
import { UnpyEvent } from 'domain/event/UnpyEvent';
import { UnpyEventView } from 'domain/event/UnpyEventView';
import { toUserViewFromUserProfil } from 'domain/profil/UserProfil.func';
import { EventAppearenceFormData } from 'primary/events/forms/EventAppearenceForm';
import { EventPublishFormData } from 'primary/events/forms/EventPublishForm';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useRetrieveFromDomain } from 'primary/hooks/useRetrieveFromDomain';
import { UseTranslateReturn } from 'primary/hooks/useTranslate';

export const isAuthorizeFromCountLimit = (count?: CountConsume, isUserUp?: boolean) => {
  if (!count) return false;
  if (isUserUp) return true;
  else return (count?.count ?? 0) < (count?.max ?? 0);
};

export const getDefaultFilterRoundFromEvent = (
  event: UnpyEvent | UnpyEventView,
  // eslint-disable-next-line @typescript-eslint/ban-types
): { roundFrom: number; roundTo: number } | {} => {
  if (event.typeEvent.code === TypeEventCodeEnum.TOURNAMENT_DOUBLE_ELEM) {
    return {};
  }
  const numMatch = event.totalParticipants ? event.totalParticipants / 2 : 0;
  const totalRound = Math.ceil(Math.log(event.totalParticipants ?? 0) / Math.log(2));
  if (numMatch > 100) {
    return {
      roundFrom: 0,
      roundTo: 1,
    };
  } else {
    return {
      roundFrom: 0,
      roundTo: totalRound,
    };
  }
};

export const useGenerateDraftFromAppearence = (
  appearence: EventAppearenceFormData,
  event?: UnpyEvent,
): UnpyEvent | undefined => {
  if (!event) return undefined;

  // @ts-ignore
  return {
    ...event,
    name: appearence.name,
    image: appearence.image,
    twitter: appearence.twitter,
    facebook: appearence.facebook,
    youtube: appearence.youtube,
    instagram: appearence.instagram,
    discord: appearence.discord,
    twitch: appearence.twitch,
  };
};

/*

 */

export const toEventViewFromEvent = (event: UnpyEvent): UnpyEventView => {
  return new UnpyEventView(
    event.id,
    event.startDateTime,
    event.endSubscribeDate,
    event.status,
    event.name,
    event.freeRegister,
    toUserViewFromUserProfil(event.creator),
    event.game,
    event.typeEvent,
    event.typeTeam,
    event.platforms,
    event.confirmationState,
    event.delayed,
    event.image,
    event.publicImageUrl,
    event.defaultImage,
    event.prizeText,
    event.pointSystem,
    event.maxTeam,
    event.totalParticipants,
  );
};

export const getInitialValueAppearence = (event: UnpyEvent) => {
  return {
    name: event.name,
    description: event.description,
    image: event.image,
    twitter: event.twitter,
    facebook: event.facebook,
    youtube: event.youtube,
    instagram: event.instagram,
    discord: event.discord,
    twitch: event.twitch,
    file: undefined,
    privacies: [],
  };
};

export const getIsReadOnly = (
  event: UnpyEvent,
  field: keyof UnpyEvent | keyof UnpyEventView,
) => {
  return !canEditField(field, event);
};

export const getInitialValueRegisterParams = (event: UnpyEvent) => {
  return {
    typeTeam: event.typeTeam?.code,
    startDateTime: format(event.startDateTime, "yyyy-MM-dd'T'HH:mm").toString(),
    endSubscribeDate: format(event.endSubscribeDate, "yyyy-MM-dd'T'HH:mm").toString(),
    freeRegister: event.freeRegister,
    singleStructureTeam: event.singleStructureTeam,
    maxTeam: event.maxTeam ?? event.bracketTeamNumber,
    bracketTeamNumber: event.bracketTeamNumber?.toString() ?? event.maxTeam?.toString(),
    platforms: event.platforms?.map((platform) => platform.code),
  };
};

export const getInitialValuesPublish = (
  t: UseTranslateReturn,
  event: UnpyEvent,
): EventPublishFormData => {
  return {
    pointSystem: event.pointSystem,
    freeRegister: event.freeRegister ?? false,
    singleStructureTeam: event.singleStructureTeam ?? false,
    typeTeamCode: event.typeTeam?.code,
    maxTeam: event.maxTeam ?? event.bracketTeamNumber,
    bracketTeamNumber: event.bracketTeamNumber ?? event.maxTeam,
    platforms: event.platforms?.map((platform) => platform.code),
    randomTeams: false,
  };
};

export const getInitialValueParameterize = (t: UseTranslateReturn, event: UnpyEvent) => {
  return {
    typeEvent: event.typeEvent?.code,
    systemPoints: event.pointSystem,
    rules: event.rules,
    game: event.game.code,
    admins: event.admins?.map((profil) => profil.idPlayer),
    platforms: event.platforms?.map((platform) => platform.code),
  };
};

export const useGetPlayerOfEvent = (
  eventId: number,
  profilId?: number,
): {
  eventParticipant: EventParticipant | null | undefined;
  retry?: () => void;
} => {
  const { eventParticipantRepository } = useContextDependency();
  const [eventParticipant, , , retry] = useRetrieveFromDomain(
    () => eventParticipantRepository.getParticipantOfEventByProfil(profilId, eventId),
    undefined,
    !!eventId && !!profilId,
  );
  if (!profilId)
    return {
      eventParticipant: null,
      retry: undefined,
    };
  return {
    eventParticipant,
    retry,
  };
};

export const useCanCreateAnnoncementEvent = (eventId?: number | string) => {
  const { eventRepository, authRepository } = useContextDependency();
  const [canCreate] = useRetrieveFromDomain(
    () => eventRepository.canCreateAnnoncement(eventId as number),
    undefined,
    !!eventId && !!authRepository?.currentUser,
  );
  if (!authRepository?.currentUser) return false;
  return canCreate;
};

export const getNumberOfTeamRegistered = (event: UnpyEvent | UnpyEventView) => {
  return event.totalParticipants;
};

export const isStartDateTimeEditable = (event: UnpyEvent) => {
  if (!event.startDateTime) return true;
  return addDays(new Date(), -2).getTime() < event.startDateTime.getTime();
};

export const getMaxTeams = (event: UnpyEvent | UnpyEventView) => {
  if (getIsRandomTeamState(event) && event?.typeTeam?.code) {
    return (event.maxTeam ?? 0) * PlayersNumberByTypeTeams[event?.typeTeam?.code]?.length;
  } else {
    return event.maxTeam;
  }
};

export const getSubStatusOfEvent = (
  event?: UnpyEvent | UnpyEventView,
): EventSubStatusEnum | undefined => {
  if (!event) return undefined;
  const isMaxTeamReached = getMaxTeams(event) === getNumberOfTeamRegistered(event);
  const statusWithoutSubStatus = [
    EventStatusEnum.DRAFT,
    EventStatusEnum.FINISH,
    EventStatusEnum.CANCELED,
    EventStatusEnum.ON_GOING,
  ];
  if (statusWithoutSubStatus.includes(event.status)) {
    return undefined;
  }

  if (event.status === EventStatusEnum.PUBLISH) {
    if (isMaxTeamReached) {
      return EventSubStatusEnum.SUBSCRIBE_CLOSE;
    }
    if (isAfter(event.endSubscribeDate, new Date())) {
      return EventSubStatusEnum.SUBSCRIBE_OPEN;
    } else {
      return EventSubStatusEnum.SUBSCRIBE_CLOSE;
    }
  }
};

export const getNextStatusOrSubStatus = (event: UnpyEvent | UnpyEventView): unknown => {
  const isTotalPArticipantReached =
    event?.randomTeams && event?.typeTeam?.code
      ? (event?.totalParticipants ?? 0) >=
        (event?.maxTeam ?? 0) * PlayersNumberByTypeTeams[event?.typeTeam?.code]?.length
      : (event?.totalParticipants ?? 0) >= (event?.maxTeam ?? 0);
  console.log('COND STATE', {
    isAfter: isAfter(new Date(), event.endSubscribeDate),
  });
  if (event.status === EventStatusEnum.DRAFT) {
    return EventStatusEnum.PUBLISH;
  }
  if (event.status === EventStatusEnum.ON_GOING) {
    return EventStatusEnum.FINISH;
  }
  if (event.status === EventStatusEnum.PUBLISH) {
    if (isTotalPArticipantReached) {
      return EventStatusEnum.ON_GOING;
    }
    if (isAfter(new Date(), event.endSubscribeDate)) {
      return EventSubStatusEnum.SUBSCRIBE_CLOSE;
    } else if (isBefore(new Date(), event.startDateTime)) {
      return EventStatusEnum.ON_GOING;
    }
  }
};

export const canRegister = (event?: UnpyEvent | UnpyEventView) => {
  if (!event) return false;
  return getSubStatusOfEvent(event) === EventSubStatusEnum.SUBSCRIBE_OPEN;
};

export const maxMatchBySessionLigueRobin = (event: UnpyEvent | UnpyEventView) => {
  if (!event.totalParticipants) return 0;
  return (event.totalParticipants * (event.totalParticipants - 1)) / 2;
};

export const canGoToOnGoing = (event: UnpyEvent) => {
  return (
    event.status === EventStatusEnum.PUBLISH && isAfter(new Date(), event.startDateTime)
  );
};

export const getNeededInfosToOpenEvent = (event: UnpyEvent): string[] => {
  const toReturn: string[] = [];
  if (event.status !== EventStatusEnum.DRAFT) return toReturn;
  else {
    neededInfosKey.forEach((key) => {
      if (key instanceof String && !event[key as keyof UnpyEvent]) {
        toReturn.push(key as string);
      } else if (key instanceof Object && !key.condition(event)) {
        toReturn.push(key.key);
      }
    });
  }
  return toReturn;
};

export const neededInfosKey = [
  'pointSystem',
  'freeRegister',
  'singleStructureTeam',
  {
    key: 'maxTeam',
    condition: (event: UnpyEvent) => event.typeEvent.code === TypeEventCodeEnum.LIGUE,
  },
  {
    key: 'bracketTeamNumber',
    condition: (event: UnpyEvent) =>
      event.typeEvent.code === TypeEventCodeEnum.TOURNAMENT,
  },
  'typeTeam',
  'platformes',
];

function canEditStartDateTime(event: UnpyEvent | UnpyEventView) {
  return (
    (event.status === EventStatusEnum.DRAFT ||
      event.status === EventStatusEnum.PUBLISH) &&
    isAfter(event.startDateTime, addDays(new Date(), -2))
  );
}

function canEditEndSubscribeDate(event: UnpyEvent | UnpyEventView) {
  return (
    event.status === EventStatusEnum.DRAFT || event.status === EventStatusEnum.PUBLISH
  );
}

export function getIsRandomTeamState(event?: UnpyEvent | UnpyEventView) {
  if (!event) return false;
  return (
    event?.randomTeams &&
    (event.status === EventStatusEnum.DRAFT || event.status === EventStatusEnum.PUBLISH)
  );
}

export const canEditField = (
  field: keyof UnpyEvent | keyof UnpyEventView,
  event?: UnpyEvent | UnpyEventView,
): boolean => {
  if (!event) return false;
  switch (field) {
    case 'id':
      return false;
    case 'randomTeams':
      return (
        event.status === EventStatusEnum.DRAFT || event.status === EventStatusEnum.PUBLISH
      );
    case 'startDateTime':
      return canEditStartDateTime(event);
    case 'endSubscribeDate':
      return canEditEndSubscribeDate(event);
    case 'status':
      return (
        event.status !== EventStatusEnum.CANCELED &&
        event.status !== EventStatusEnum.FINISH
      );
    case 'name':
      return event.status === EventStatusEnum.DRAFT;
    case 'registerPrice':
      return (
        event.status === EventStatusEnum.DRAFT || event.status === EventStatusEnum.PUBLISH
      );
    case 'freeRegister':
      return event.status === EventStatusEnum.DRAFT;
    case 'singleStructureTeam':
      return event.status === EventStatusEnum.DRAFT;
    case 'creationDate':
      return false;
    case 'creator':
      return false;
    case 'game':
      return event.status === EventStatusEnum.DRAFT;
    case 'typeEvent':
      return event.status === EventStatusEnum.DRAFT;
    case 'typeTeam':
      return event.status === EventStatusEnum.DRAFT;
    case 'rules':
      return event.status !== EventStatusEnum.CANCELED;
    case 'platforms':
      return (
        event.status === EventStatusEnum.DRAFT || event.status === EventStatusEnum.PUBLISH
      );
    case 'description':
      return true;
    case 'maxTeam':
      return event.status === EventStatusEnum.DRAFT;
    case 'confirmationState':
      return false;
    case 'delayed':
      return false;
    case 'bracketTeamNumber':
      return event.status === EventStatusEnum.DRAFT;
    case 'twitter':
      return true;
    case 'facebook':
      return true;
    case 'youtube':
      return true;
    case 'instagram':
      return true;
    case 'discord':
      return true;
    case 'twitch':
      return true;
    case 'image':
      return true;
    case 'typeBracket':
      return event.status === EventStatusEnum.DRAFT;
    case 'typeRonde':
      return event.status === EventStatusEnum.DRAFT;
    case 'typeBo':
      return event.status === EventStatusEnum.DRAFT;
    case 'admins':
      return (
        event.status !== EventStatusEnum.FINISH &&
        event.status !== EventStatusEnum.CANCELED
      );
    case 'classement':
      return event.status === EventStatusEnum.ON_GOING;
    case 'pointSystem':
      return (
        event.status === EventStatusEnum.DRAFT || event.status === EventStatusEnum.PUBLISH
      );
    case 'publicImageUrl':
      return false;
    case 'defaultImage':
      return false;
    case 'prizeText':
      return event.status === EventStatusEnum.DRAFT;
    default:
      return false;
  }
};
