import { Button } from '@mui/material';
import { UserPreviewCardProfil } from 'primary/Components/Profils/User/UserPreviewCardProfil';
import { routesConfig } from 'primary/Configs/Routes.config';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useRetrieveFromDomain } from 'primary/hooks/useRetrieveFromDomain';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const SliderLastRegisters = () => {
  const { userProfilRepository } = useContextDependency();
  const [lastRegisteredProfil] = useRetrieveFromDomain(
    () => userProfilRepository.search({}, 0, 10),
    undefined,
  );
  const navigate = useNavigate();

  return (
    <div className={'-sliderLastRegisters'}>
      <div className={'-title'}>
        <h2>Derniers inscrits</h2>
        <Button
          variant={'contained'}
          onClick={() => navigate(routesConfig.mercatoSubPath.mercatoPlayer.to())}
          color={'primary'}
          className={'-comingSoon'}
        >
          Voir plus
        </Button>
      </div>
      <div className={'-scrollableContainer'}>
        {lastRegisteredProfil?.content?.map((content, index) => (
          <UserPreviewCardProfil
            key={content.idProfil ?? '' + index}
            profil={content}
            userName={content.idPlayer}
          />
        ))}
      </div>
    </div>
  );
};
