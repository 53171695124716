import './ModalTeamEvent.scss';

import { Edit, Warning } from '@mui/icons-material';
import { Button, Chip, Collapse, Divider, IconButton } from '@mui/material';
import classNames from 'classnames';
import { EventStatusEnum } from 'domain/event/EventStatusEnum';
import { EventParticipant } from 'domain/event/participants/EventParticipant';
import { EventParticipantStatusEnum } from 'domain/event/participants/EventParticipantStatusEnum';
import { PlayerRoleEnum } from 'domain/event/PlayerRoleEnum';
import { PlayerTeam } from 'domain/event/PlayerTeam';
import { PlayersNumberByTypeTeams } from 'domain/event/TypeTeam';
import { UnpyEvent } from 'domain/event/UnpyEvent';
import { getIsRandomTeamState } from 'domain/event/UnpyEvent.func';
import { UnpyEventView } from 'domain/event/UnpyEventView';
import { Team } from 'domain/team/Team';
import { ITeamRepository } from 'domain/team/Team.repository';
import { TranslationType } from 'domain/translation/Translation.repository';
import { CoupleButtonValidCancel } from 'primary/Components/CoupleButtonValidCancel/CoupleButtonValidCancel';
import { InputSelectMui } from 'primary/Components/Input/InputSelectMui';
import {
  ChangeHandlerTypeParam,
  InputWrapper,
} from 'primary/Components/Input/InputWrapper';
import { LoadingScreen } from 'primary/Components/LoadingScreen/LoadingScreen';
import { TagPlayer } from 'primary/Components/TagPlayer/TagPlayer';
import { sendEventToastMessage } from 'primary/Components/Toast/Toast.helper';
import { routesConfig } from 'primary/Configs/Routes.config';
import { useRetrieveFromDomain } from 'primary/hooks/useRetrieveFromDomain';
import { useTranslate, UseTranslateReturn } from 'primary/hooks/useTranslate';
import { TeamMembersViewDatatable } from 'primary/teams/TeamMembersViewDatatable';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { Title } from '../../Components/Title/Title';
import { useContextDependency } from '../../hooks/useContextDependency';

export interface ModalTeamEventProps {
  handleClose?: () => void;
  afterChanges: () => void;
  teamRegistered?: EventParticipant | null;
  event: UnpyEventView | UnpyEvent;
}

const ModalTeamEvent = ({
  event,
  teamRegistered,
  handleClose,
  afterChanges,
}: ModalTeamEventProps) => {
  const t = useTranslate();
  const [isConfirmDisplayed, setIsConfirmDisplayed] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const { authRepository, eventParticipantRepository, subscribtionRepository } =
    useContextDependency();
  const [teamParticipant, , , retry] = useRetrieveFromDomain(
    () =>
      eventParticipantRepository.getEventParticipantById(teamRegistered?.id as number),
    undefined,
    !!teamRegistered,
  );
  const isEditable = teamParticipant && event.status === EventStatusEnum.PUBLISH;

  const isChiefConnected =
    teamParticipant?.team.players.find((p) => p.role === PlayerRoleEnum.CHIEF)?.player
      ?.idProfil === authRepository?.currentUser?.userProfil?.id;

  const isMemberConnected = teamParticipant?.team.players.some(
    (p) => p.player?.idProfil === authRepository?.currentUser?.userProfil?.id,
  );

  console.log('CONDITION DISABLED', {
    isChiefConnected,
    status: event.status,
  });
  const navigate = useNavigate();
  const handleDeleteTeamConfirm = () => {
    setIsConfirmDisplayed(true);
  };
  const handleDeleteTeam = () => {
    if (!teamParticipant) return;
    setIsDeleteLoading(true);
    eventParticipantRepository
      .deleteEventParticipantForEvent(teamParticipant.id, event.id)
      .then(() => {
        sendEventToastMessage('toast.event.unregister', 'success');
        handleClose?.();
        afterChanges();
        navigate(routesConfig.eventList.to);
      })
      .finally(() => {
        setIsDeleteLoading(true);
      });
  };

  return (
    <div className={'modal-team-event'}>
      {!teamParticipant && <LoadingScreen loading />}
      {teamParticipant && (
        <>
          <div className={'-content'}>
            <div className={'-header'}>
              <div className={'-headerWithAction'}>
                <Title
                  threeQuarter
                  bordered
                  title={
                    isMemberConnected
                      ? getIsRandomTeamState(event)
                        ? t('event.myTeamModal.titleRandom')
                        : t('event.myTeamModal.title')
                      : t('event.teamModal.title', { name: teamRegistered?.team.name })
                  }
                  subTitle={
                    isMemberConnected &&
                    event?.randomTeams &&
                    event?.typeTeam?.code &&
                    event?.status === EventStatusEnum.PUBLISH
                      ? t('event.myTeamModal.titleRandomNotice', {
                          nbJoueur:
                            PlayersNumberByTypeTeams[event?.typeTeam?.code]?.length,
                        })
                      : undefined
                  }
                  level={2}
                />
              </div>

              <div className={'-titleChip'}>
                <h2>{teamParticipant.team.name}</h2>
                <Chip
                  className={classNames('-tagStateTeam')}
                  label={t(
                    `enum.eventParticipantStatus.${teamParticipant.status}` as TranslationType,
                  )}
                />
              </div>
              {isMemberConnected &&
                teamParticipant.status === EventParticipantStatusEnum.WAIT_VALID && (
                  <div className={'-warningBlock'}>
                    <Warning />
                    <span>{t('event.myTeamModal.waitValid')}</span>
                  </div>
                )}
              {isMemberConnected &&
                teamParticipant.status === EventParticipantStatusEnum.WAIT_VALIDATE && (
                  <div className={'-warningBlock'}>
                    <Warning />
                    <span>{t('event.myTeamModal.waitValidate')}</span>
                  </div>
                )}
            </div>
            <Divider flexItem />
            {!event?.randomTeams || event?.status !== EventStatusEnum.PUBLISH ? (
              <div className={'-playerInTeam'}>
                <h3>{t('event.myTeamModal.playersList')}</h3>
                <TeamMembersViewDatatable
                  event={event}
                  team={teamParticipant.team}
                  reFetch={retry}
                  disabled={
                    !isMemberConnected || event.status !== EventStatusEnum.PUBLISH
                  }
                />
              </div>
            ) : undefined}
          </div>
        </>
      )}

      <div className={'-closeBtnContainer'}>
        {teamParticipant && isMemberConnected && isChiefConnected && (
          <div className={'-unsubscribeBtnContainer'}>
            <Button
              className={'-button'}
              variant={'contained'}
              color={'error'}
              disabled={
                !isEditable || !isChiefConnected || isDeleteLoading || isConfirmDisplayed
              }
              onClick={handleDeleteTeamConfirm}
            >
              {t('event.myTeamModal.unregister')}
            </Button>
          </div>
        )}
        <Button className={'-button'} variant={'contained'} onClick={handleClose}>
          {t('modals.waitingForActions.closeBtn')}
        </Button>
      </div>
      <Collapse in={isConfirmDisplayed}>
        <div className={'-confirmContainer'}>
          <span>Êtes-vous sûr de vouloir vous désinscrire ?</span>
          <CoupleButtonValidCancel
            loading={isDeleteLoading}
            onClickCancel={() => setIsConfirmDisplayed(false)}
            onClickValid={handleDeleteTeam}
          />
        </div>
      </Collapse>
    </div>
  );
};

type PlayerItemBlocProps = {
  player: PlayerTeam;
  team: Team;
  onSubmitted: () => void;
  isEditable?: boolean;
};

const PlayerTeamRoleBox = ({
  player,
  team,
  onSubmitted,
  isEditable,
}: PlayerItemBlocProps) => {
  const [editRole, setEditRole] = useState(false);
  const t = useTranslate();
  return (
    <div className={'-playersContainer'}>
      <div className={'-player'}>
        <TagPlayer key={player?.player?.idPlayer} player={player.player} />
        <div className={'-roleEdit'}>
          {!editRole && (
            <div className={'-noRoleEdit'}>
              <span>{t(`enum.playerRole.${player.role}`)}</span>
              {isEditable && (
                <IconButton onClick={() => setEditRole(true)} className={'-editRole'}>
                  <Edit />
                </IconButton>
              )}
            </div>
          )}
          {editRole && (
            <FormEditRoleField
              key={player?.player?.id}
              player={player}
              team={team}
              onSubmitted={() => {
                onSubmitted();
                setEditRole(false);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalTeamEvent;

export type PlayerRoleEdit = {
  playerId?: number;
  role?: PlayerRoleEnum;
};

const validationSchema = (t: UseTranslateReturn) =>
  yup.object({
    playerId: yup.number().required(t('general.forms.errors.required')),
    role: yup.string().required(t('general.forms.errors.required')),
  });

const useOnSubmit =
  (
    teamRepository: ITeamRepository,
    setLoading: (teamCreatingLoading: boolean) => void,
    onSubmitted: (player: PlayerTeam) => void,
    idTeam: number,
  ) =>
  (data: PlayerRoleEdit) => {
    setLoading(true);
    teamRepository
      .editRole(idTeam, data.playerId as number, data.role?.toString() as string)
      .then((player: PlayerTeam) => {
        onSubmitted(player);
      })
      .finally(() => {
        setLoading(false);
      });
  };

type FormEditRoleFieldProps = {
  player: PlayerTeam;
  team: Team;
  initialRole?: PlayerRoleEnum;
  onSubmitted: (player: PlayerTeam) => void;
};

export const FormEditRoleField = ({
  player,
  team,
  onSubmitted,
  initialRole,
}: FormEditRoleFieldProps) => {
  const { teamRepository } = useContextDependency();
  const t = useTranslate();
  const [loading, setLoading] = useState(false);
  const onSubmit = useOnSubmit(teamRepository, setLoading, onSubmitted, team.id);
  const [role, setRole] = useState<PlayerRoleEnum | undefined>(initialRole);

  useEffect(() => {
    if (role && role !== initialRole) {
      onSubmit({
        role: role as PlayerRoleEnum,
        //@ts-ignore
        playerId: player?.idProfil,
      });
    }
  }, [role]);

  const optRole = Object.values(PlayerRoleEnum).map((roleOpt) => ({
    label: t(`enum.playerRole.${roleOpt as PlayerRoleEnum}`),
    value: roleOpt,
  }));

  return (
    <div className={'-inputRoleContainer'}>
      <InputWrapper
        className={'-playersField__role'}
        Input={InputSelectMui}
        value={role}
        inputProps={{
          //@ts-ignore
          options: optRole,
          placeholder: t('event.form.placeholders.role'),
          menuPortalTarget: document.getElementById('root'),
          //@ts-ignore
          onChange: (event: ChangeHandlerTypeParam) => {
            setRole(event.target.value as PlayerRoleEnum);
          },
          value: role,
          disabled: loading,
        }}
      />
    </div>
  );
};
