import { Button } from '@mui/material';
import { UnpyEventFilters } from 'domain/event/UnpyEvent';
import { UnpyEventView } from 'domain/event/UnpyEventView';
import { GAME_ACTIVE_LIST } from 'domain/game/GameActiveList';
import { EventCard } from 'primary/Components/Event/EventCard';
import { ListGameSelectable } from 'primary/Components/UserStatsGameForms/ListGameSelectable';
import { routesConfig } from 'primary/Configs/Routes.config';
import { useContextDependency } from 'primary/hooks/useContextDependency';
import { useFetchWithFilterPage } from 'primary/hooks/useFetchWithFilterPage';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const SliderLastEvents = () => {
  const { eventRepository } = useContextDependency();

  const {
    fetchedResource,
    goToPage,
    loading,
    activeFilters,
    changeNbPerPage,
    setFilters,
  } = useFetchWithFilterPage<UnpyEventView, UnpyEventFilters>({
    keyStoreFilters: 'event-home-slider',
    filters: {},
    callApi: (filters, page: number) => eventRepository.searchPublic(filters, 0, 5),
  });
  const games = activeFilters?.filters?.games;
  const navigate = useNavigate();
  return (
    <div className={'sliderLastEvents'}>
      <div className={'-title'}>
        <Button
          variant={'contained'}
          onClick={() => navigate(routesConfig.events.to())}
          color={'primary'}
          className={'-comingSoon'}
        >
          Voir plus
        </Button>
        <h2>Trouver un événement</h2>
      </div>
      <ListGameSelectable
        inline
        size={'small'}
        flex
        games={Object.values(GAME_ACTIVE_LIST)}
        action={(gamecode) => {
          if (activeFilters?.filters?.games?.includes(gamecode)) {
            const filtered = activeFilters?.filters?.games?.filter((g) => g !== gamecode);
            setFilters({
              ...activeFilters,
              games: filtered?.length
                ? activeFilters?.filters?.games?.filter((g) => g !== gamecode)
                : undefined,
            });
          } else {
            setFilters({
              ...activeFilters,
              games: [...(activeFilters?.filters?.games ?? []), gamecode],
            });
          }
        }}
        //@ts-ignore
        gamesSelected={games ?? []}
      />
      <div className={'-eventsOverviewScrollable'}>
        <div className={'-scrollableContainer'}>
          {!loading &&
            fetchedResource?.content?.length !== 0 &&
            fetchedResource?.content?.map((content, index) => (
              <EventCard event={content} key={index} />
            ))}
          {!loading && !fetchedResource?.content?.length && (
            <div className={'-noContent'}>
              Aucun événement prévus {games ? ` sur ${games?.join(', ')}` : ''}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
