import './NeonForm.scss';

import { Check, Lock } from '@mui/icons-material';
import { Button } from '@mui/material';
import classNames from 'classnames';
import { Neon } from 'domain/neon/Neon';
import { NeonType } from 'domain/neon/NeonType';
import { getNeonBarreStyle, getNeonBorderStyle } from 'domain/profil/Profil.func';
import { isUserUp, isUserUpPremium } from 'domain/user/User.helper';
import { routesConfig } from 'primary/Configs/Routes.config';
import { ProfilAppearenceFormData } from 'primary/Parameters/user/Profil/UserProfilAppearence';
import React, { FC, useEffect, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { UseFormReturn } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { useContextDependency } from '../../hooks/useContextDependency';
import { useTranslate } from '../../hooks/useTranslate';

interface NeonFormProps {
  form: UseFormReturn<ProfilAppearenceFormData>;
  type: NeonType;
}

interface BoxNeonSelectableProps {
  onClick?: () => void;
  isSelected: boolean;
  isLockUp?: boolean;
  readonly?: boolean;
  helperText?: string;
  neon: Neon;
}

export const BoxNeonSelectable = ({
  neon,
  onClick,
  isLockUp,
  helperText,
  isSelected,
  readonly,
}: BoxNeonSelectableProps) => {
  const neonBorderClassName = getNeonBorderStyle(neon.color);
  return (
    <button
      className={classNames('boxNeonSelectableContainer', { '-isSelectable': !readonly })}
      onClick={
        readonly
          ? undefined
          : (e) => {
              e.preventDefault();
              e.stopPropagation();
              onClick?.();
            }
      }
    >
      <div className={classNames('boxNeonSelectable', neonBorderClassName)}></div>
      {isSelected && (
        <div className={'boxNeonSelectableContainer__overSelected'}>
          <Check color={'success'} />
        </div>
      )}
      {isLockUp && (
        <div className={'boxNeonSelectableContainer__overLocked'}>
          <Lock />
        </div>
      )}
    </button>
  );
};

export type NeonPart =
  | 'neonPhoto'
  | 'neonBar'
  | 'neonLitleBar'
  | 'neonBorder'
  | undefined;

export const NeonForm: FC<NeonFormProps> = ({ form, type }) => {
  const t = useTranslate();

  const { authRepository } = useContextDependency();

  const [selectedPart, setSelectedPart] = useState<NeonPart>(undefined);

  const [selectedColor, setSelectedColor] = useState<{
    neonPhoto?: string;
    neonBar?: string;
    neonLitleBar?: string;
    neonBorder?: string;
  }>({
    neonPhoto: form.getValues('neonPhoto'),
    neonBar: form.getValues('neonBar'),
    neonLitleBar: form.getValues('neonLitleBar'),
    neonBorder: form.getValues('neonBorder'),
  });

  const setSelectedColorByType = (typeEdit?: string, color?: string) => {
    if (typeEdit) {
      setSelectedColor((prev) => ({
        ...prev,
        [typeEdit]: color,
      }));
    } else {
      setSelectedColor((prev) => ({
        ...prev,
        neonPhoto: color,
        neonBar: color,
        neonLitleBar: color,
        neonBorder: color,
      }));
    }
  };

  useEffect(() => {
    form.setValue('neonPhoto', selectedColor.neonPhoto);
    form.setValue('neonBar', selectedColor.neonBar);
    form.setValue('neonLitleBar', selectedColor.neonLitleBar);
    form.setValue('neonBorder', selectedColor.neonBorder);
    if (
      Object.values(selectedColor).some((value) =>
        Object.values(selectedColor).find((valueCompare) => valueCompare !== value),
      )
    ) {
      form.setError('neonBorder', {
        message: "Vous ne respectez pas les condition d'abonnement",
      });
    }
    console.log('VALUES SETTED');
  }, [selectedColor]);
  const error =
    form.formState.errors.neonBar ||
    form.formState.errors.neonLitleBar ||
    form.formState.errors.neonPhoto ||
    form.formState.errors.neonBorder;
  return (
    <div className={'neon-form'}>
      <div className={'neon-form__noUp'}>
        <div className={'neon-form__title'}>
          <h4>{t('parameters.menus.profil.user.form.neonLabel')} *</h4>
          {error && (
            <div className={'-errorUpContainer'}>
              <span className={'-error'}>
                Vous ne respectez pas les conditions d'abonnement
              </span>
              <Button
                onClick={() => {
                  setSelectedColor({
                    neonPhoto: undefined,
                    neonBar: undefined,
                    neonLitleBar: undefined,
                    neonBorder: undefined,
                  });
                  form.clearErrors();
                }}
              >
                réinitialiser les couleurs
              </Button>
            </div>
          )}
        </div>
        <div className={'neon-form__part'}>
          <div className={'neon-form__title'}>
            <h5>{t('parameters.menus.profil.user.form.neonPartLabel')}</h5>
          </div>
          <div className={'-neonParts'}>
            <PartNeonSelectable
              type={type}
              lock={false}
              part={undefined}
              label={'Tout'}
              isSelected={selectedPart === undefined}
              setSelectedPart={() => setSelectedPart(undefined)}
              selectedColor={selectedColor['neonPhoto']}
            />
            <PartNeonSelectable
              type={type}
              lock={!isUserUpPremium(authRepository?.currentUser)}
              part={'neonPhoto'}
              label={'Photo'}
              isSelected={selectedPart === 'neonPhoto'}
              setSelectedPart={() => setSelectedPart('neonPhoto')}
              selectedColor={selectedColor['neonPhoto']}
            />
            {type === NeonType.PLAYER && (
              <PartNeonSelectable
                type={type}
                lock={!isUserUpPremium(authRepository?.currentUser)}
                part={'neonLitleBar'}
                label={'Barre haute'}
                isSelected={selectedPart === 'neonLitleBar'}
                setSelectedPart={() => setSelectedPart('neonLitleBar')}
                selectedColor={selectedColor['neonLitleBar']}
              />
            )}

            <PartNeonSelectable
              type={type}
              lock={!isUserUpPremium(authRepository?.currentUser)}
              part={'neonBorder'}
              label={'Bordures'}
              isSelected={selectedPart === 'neonBorder'}
              setSelectedPart={() => setSelectedPart('neonBorder')}
              selectedColor={selectedColor['neonBorder']}
            />
            <PartNeonSelectable
              type={type}
              lock={!isUserUpPremium(authRepository?.currentUser)}
              part={'neonBar'}
              label={'Barre basse'}
              isSelected={selectedPart === 'neonBar'}
              setSelectedPart={() => setSelectedPart('neonBar')}
              selectedColor={selectedColor['neonBar']}
            />
          </div>
          {!isUserUpPremium(authRepository?.currentUser) && (
            <div className={'-errorUpContainer'}>
              <span className={'-warning'}>
                <Link to={routesConfig.up.to()} className={'-goUpLink'}>
                  Passez <strong>Unpy UP PREMIUM</strong>
                </Link>{' '}
                pour sélectionner une couleur de néon par éléments !
              </span>
            </div>
          )}
        </div>
        <div className={'neon-form__colorPicker'}>
          <div className={'neon-form__title marged'}>
            <h5>{t('parameters.menus.profil.user.form.neonColorLabel')}</h5>
            {!isUserUp(authRepository?.currentUser) && <Lock />}
          </div>
          <HexColorPicker
            color={
              (selectedPart ? selectedColor[selectedPart] : selectedColor['neonBar']) ??
              '#202020'
            }
            onChange={(color) => setSelectedColorByType(selectedPart, color)}
          />
          {!isUserUp(authRepository?.currentUser) && (
            <div className={'-errorUpContainer'}>
              <span className={'-warning'}>
                <Link to={routesConfig.up.to()} className={'-goUpLink'}>
                  Passez <strong>Unpy UP </strong>
                </Link>{' '}
                pour sélectionner une couleur de néon personnalisée
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
type PartNeonSelectableProps = {
  label: string;
  part: NeonPart;
  isSelected: boolean;
  setSelectedPart: () => void;
  selectedColor?: string;
  lock: boolean;
  type: NeonType;
};
export const PartNeonSelectable = ({
  part,
  selectedColor,
  setSelectedPart,
  isSelected,
  label,
  lock,
  type,
}: PartNeonSelectableProps) => {
  return (
    <div
      onClick={setSelectedPart}
      className={classNames('partNeonSelectable', { '-selected': isSelected })}
    >
      <span>
        {label} {lock && <Lock />}
      </span>
      {type === NeonType.PLAYER && (
        <UserProfilFigureNeon displayPart={part} color={selectedColor} />
      )}
      {type === NeonType.STRUCTURE && (
        <StructureProfilFigureNeon displayPart={part} color={selectedColor} />
      )}
    </div>
  );
};

type UserProfilFigureNeonProps = {
  color?: string;
  displayPart: NeonPart;
};

export const UserProfilFigureNeon = ({
  displayPart,
  color,
}: UserProfilFigureNeonProps) => {
  const stylePhoto = getNeonBorderStyle(color);
  const styleBar = getNeonBarreStyle(color);
  const styleLitleBar = getNeonBarreStyle(color);
  const styleBorder = getNeonBorderStyle(color);
  return (
    <div
      className={'userPreviewCardFigure'}
      style={!displayPart || displayPart === 'neonBorder' ? styleBorder : undefined}
    >
      <div className={'header'}>
        {!displayPart || displayPart === 'neonPhoto' ? (
          <div className={'photo'} style={stylePhoto}></div>
        ) : null}
        {!displayPart || displayPart === 'neonLitleBar' ? (
          <div
            className={`littleBar ${displayPart === 'neonLitleBar' ? 'marged' : ''}`}
            style={styleLitleBar}
          ></div>
        ) : null}
      </div>
      {!displayPart || displayPart === 'neonBar' ? (
        <div className={'bar'} style={styleBar}></div>
      ) : null}
    </div>
  );
};

export const StructureProfilFigureNeon = ({
  displayPart,
  color,
}: UserProfilFigureNeonProps) => {
  const stylePhoto = getNeonBorderStyle(color);
  const styleBar = getNeonBarreStyle(color);
  const styleBorder = getNeonBorderStyle(color);
  return (
    <div
      className={'structurePreviewCardFigure'}
      style={!displayPart || displayPart === 'neonBorder' ? styleBorder : undefined}
    >
      <div className={'header'}>
        {!displayPart || displayPart === 'neonPhoto' ? (
          <div className={'banner'} style={stylePhoto}></div>
        ) : null}
      </div>
      {!displayPart || displayPart === 'neonBar' ? (
        <div className={'bar'} style={styleBar}></div>
      ) : null}
    </div>
  );
};
