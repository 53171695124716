export const getBorderNeonStyleDefault = () => {
  return getBorderNeonStyleCustom('#FFFFFF');
};
export const getBorderNeonStyleCustom = (color: string) => {
  return {
    border: `1px solid ${color}`,
    boxShadow: `0 0 5px 3px ${color}`,
    ['border-color']: `${color}`,
    ['-webkit-box-shadow']: `0px 0px 5px 3px ${color}`,
    ['-moz-box-shadow']: ` 0px 0px 5px 3px  ${color}`,
    [' box-shadow']: `0px 0px 5px 3px  ${color}`,
    ['&.-hoverable:not](:has(button:hover)):hover']: {
      ['border-color']: `${color}`,
      ['-webkit-box-shadow']: `0px 0px 10px 3px ${color}`,
      ['-moz-box-shadow']: `0px 0px 10px 3px  ${color}`,
      ['box-shadow']: `0px 0px 10px 3px  ${color}`,
    },
  };
};

export const getNeonBarreStyleCustom = (color = '#FFFFFF') => {
  return {
    width: '100%',
    height: '2px',
    ['min-height']: '2px',
    ['max-height']: '2px',
    ['background-color']: color ?? '#FFFFFF',
    border: `1px solid ${color ?? '#FFFFFF'}`,
    boxShadow: `0 0 5px 3px ${color ?? '#FFFFFF'}`,
    ['-webkit-box-shadow']: `0 0 0.2rem ${color ?? '#FFFFFF'}, 0 0 0.2rem ${
      color ?? '#FFFFFF'
    }, 0 0 1rem ${color ?? '#FFFFFF'}, 0 0 0.8rem ${color ?? '#FFFFFF'}, 0 0 0.8rem ${
      color ?? '#FFFFFF'
    }, inset 0 0 0.4rem ${color ?? '#FFFFFF'}`,
    ['-moz-box-shadow']: ` 0 0 0.2rem ${color ?? '#FFFFFF'}, 0 0 0.2rem ${
      color ?? '#FFFFFF'
    }, 0 0 1rem ${color ?? '#FFFFFF'}, 0 0 0.8rem ${color ?? '#FFFFFF'}, 0 0 0.8rem ${
      color ?? '#FFFFFF'
    }, inset 0 0 0.4rem ${color ?? '#FFFFFF'}`,
    [' box-shadow']: `0 0 0.2rem ${color ?? '#FFFFFF'}, 0 0 0.2rem ${
      color ?? '#FFFFFF'
    }, 0 0 1rem ${color ?? '#FFFFFF'}, 0 0 0.8rem ${color ?? '#FFFFFF'}, 0 0 0.8rem ${
      color ?? '#FFFFFF'
    }, inset 0 0 0.4rem ${color ?? '#FFFFFF'}`,
  };
};

export const getVerticalNeonBarreStyleCustom = (color = 'transparent') => {
  return {
    height: '100%',
    width: '2px',
    ['min-width']: '2px',
    ['max-width']: '2px',
    ['background-color']: color ?? '#202020',
    border: `1px solid ${color ?? '#202020'}`,
    boxShadow: `0 0 5px 3px ${color ?? '#202020'}`,
    ['-webkit-box-shadow']: `0 0 0.2rem ${color ?? '#202020'}, 0 0 0.2rem ${
      color ?? '#202020'
    }, 0 0 1rem ${color ?? '#202020'}, 0 0 0.8rem ${color ?? '#202020'}, 0 0 0.8rem ${
      color ?? '#202020'
    }, inset 0 0 0.4rem ${color ?? '#202020'}`,
    ['-moz-box-shadow']: ` 0 0 0.2rem ${color ?? '#202020'}, 0 0 0.2rem ${
      color ?? '#202020'
    }, 0 0 1rem ${color ?? '#202020'}, 0 0 0.8rem ${color ?? '#202020'}, 0 0 0.8rem ${
      color ?? '#202020'
    }, inset 0 0 0.4rem ${color ?? '#202020'}`,
    [' box-shadow']: `0 0 0.2rem ${color ?? '#202020'}, 0 0 0.2rem ${
      color ?? '#202020'
    }, 0 0 1rem ${color ?? '#202020'}, 0 0 0.8rem ${color ?? '#202020'}, 0 0 0.8rem ${
      color ?? '#202020'
    }, inset 0 0 0.4rem ${color ?? '#202020'}`,
  };
};
